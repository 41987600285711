import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firebaseHandle } from '../user_accounts/Authentification';

export default function CreateExtraContactBox(props) {

    const [email, setEmail] = useState('');
    const [firm, setFirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [job, setJob] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [mobilePhoneBusiness, setMobilePhoneBusiness] = useState('');
    const [phone, setPhone] = useState('');
    const [profileImage, setProfileImage] = useState('images/users/default.jpg');

    


    const handleSave = () => {                              
        firebaseHandle.db.collection("ExtraContacts").add({
            email: email, 
            firm: firm, 
            firstName: firstName, 
            job: job, 
            lastName: lastName, 
            mobilePhone: mobilePhone, 
            mobilePhoneBusiness: mobilePhoneBusiness, 
            profileImage: profileImage, 
            phone: phone});
        handleClose();
    }

    const handleClose = () => {
        props.close();
        setEmail('');
        setFirm('');
        setFirstName('');
        setJob('');
        setLastName('');
        setMobilePhone('');
        setMobilePhoneBusiness('');
        setPhone('');
    }

    return (
        <Dialog onClose={handleClose} open={props.show}>
            <DialogTitle>Neuen Kontakt erstellen</DialogTitle>


            <TextField
                label="Vorname"
                variant="outlined"
                value={firstName}
                onChange={(evt)=>{setFirstName(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Nachname"
                variant="outlined"
                value={lastName}
                onChange={(evt)=>{setLastName(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(evt)=>{setEmail(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Firma"
                variant="outlined"
                value={firm}
                onChange={(evt)=>{setFirm(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Tätigkeit"
                variant="outlined"
                value={job}
                onChange={(evt)=>{setJob(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Telefonnummer"
                variant="outlined"
                value={phone}
                onChange={(evt)=>{setPhone(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Geschäfts-Handy"
                variant="outlined"
                value={mobilePhoneBusiness}
                onChange={(evt)=>{setMobilePhoneBusiness(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Private Nummer"
                variant="outlined"
                value={mobilePhone}
                onChange={(evt)=>{setMobilePhone(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{float: 'right'}}
            >
                Erstellen
            </Button>

        </Dialog>
    )
}