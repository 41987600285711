import React, { useState } from 'react';
import { resetPassword } from './Authentification';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';

export default function ResetPassword() {

    const [email, setEmail] = useState('');
    const [alert, setAlert] = useState('');
    const [showForm, setShowForm] = useState(true);


    const submitPasswordReset = (event) => {
        event.preventDefault();
        setAlert('');

        if(email !== '')
        {
            resetPassword(email);
            setShowForm(false);
        }
        else
        {
            setAlert(<Alert style={{marginBottom: '25px'}} severity="error">Bitte geben Sie Ihre E-Mail Adresse an</Alert>);
        }
        
    }


    const form = () => {
        if(showForm)
        {
            return (
                <form id="resetPasswordForm" noValidate autoComplete="on" onSubmit={submitPasswordReset}>
                    {alert}
                    <TextField style={{width: '100%', marginBottom: '20px'}} label="E-Mail" variant="outlined" value={email} onChange={(event) => {setEmail(event.target.value)}} />
                    <Button variant="contained" color="primary" type="submit" style={{float: 'right'}}>
                        Passwort zurücksetzen
                    </Button>
                </form>
            );
        }
        else
        {
            return (<Alert severity="success">Wir haben Ihnen eine E-Mail mit dem Rücksetzungs-Link gesendet</Alert>);
        }
    }


    return (
        <Grid container spacing={0} justify="center">
            <Grid item xs={12} sm={10} md={6} lg={3}>
                <Paper style={{padding: '20px'}} elevation={15} >
                    {form()}
                </Paper>
                <br />
                <Link component={RouterLink} to="/" style={{marginRight: '10px'}}>Zurück zur Anmeldung</Link> 
            </Grid>
        </Grid>
        
    )
}
