import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {updateUserProfile} from '../user_accounts/Authentification';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import EditableTextField from './EditableTextField';
import ProfileImage from './ProfileImage';
import './Settings.css';
import Vacation from './Vacation';
import { firebaseHandle } from '../user_accounts/Authentification';

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';
import IconButton from '@material-ui/core/IconButton';
import { Edit } from '@material-ui/icons';


export default function Profile(props) {

    return (
        
            <Grid container spacing={0} justify="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Paper style={{padding: '20px'}} elevation={15} >
                        <h2>Profil</h2>
                        <Grid item container spacing={0} justify="center">
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <ProfileImage userProfile={props.userProfile} />
                                <Vacation vacation={props.userProfile.vacation} userProfileRef={props.userProfileRef} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={8} lg={9}>
                                <TextField disabled label="Vorname" defaultValue={props.userProfile.firstName} /><br/><br/>
                                <TextField disabled label="Nachname" defaultValue={props.userProfile.lastName} /><br/><br/>
                                <TextField disabled label="Geburtstag" defaultValue={new Date(props.userProfile.birthday * 1000).toLocaleDateString('de-DE')} /><br/><br/>
                                <TextField disabled label="E-Mail" defaultValue={props.userProfile.email} /><IconButton onClick={()=>{firebaseHandle.auth.signOut(); window.location.href='/email-update'}}><Edit /></IconButton><br/><br/>
                                <EditableTextField value={props.userProfile.phone} label="Telefon" field="phone"/><br/><br/>
                                <EditableTextField value={props.userProfile.mobilePhoneBusiness} label="Mobiltelefon geschäftlich" field="mobilePhoneBusiness"/><br/><br/>
                                <EditableTextField value={props.userProfile.mobilePhone} label="Mobiltelefon privat" field="mobilePhone"/><br/><br/>
                                <TextField disabled label="Firma" defaultValue={props.userProfile.firm} /><br/><br/>
                                <EditableTextField value={props.userProfile.job} label="Tätigkeit" field="job"/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Paper style={{padding: '20px'}} elevation={15} >
                        <h2>Darstellung</h2>
                        <Grid item container spacing={0} justify="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={props.userProfile.darkMode} onChange={()=>{
                                            updateUserProfile({darkMode: !props.userProfile.darkMode});
                                        }} />
                                    }
                                    label="Abgedunkelter Modus"
                                />

                                <h4>Primärfarbe</h4>
                                <ToggleButtonGroup
                                    value={props.userProfile.primaryColor}
                                    exclusive
                                    onChange={(event, newPrimary)=> {
                                            updateUserProfile({primaryColor: newPrimary});
                                        }
                                    }
                                >
                                    <ToggleButton value="red">
                                        <div className="color-field" style={{backgroundColor: red[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="pink">
                                        <div className="color-field" style={{backgroundColor: pink[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="purple">
                                        <div className="color-field" style={{backgroundColor: purple[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="deepPurple">
                                        <div className="color-field" style={{backgroundColor: deepPurple[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="indigo">
                                        <div className="color-field" style={{backgroundColor: indigo[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="blue">
                                        <div className="color-field" style={{backgroundColor: blue[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="lightBlue">
                                        <div className="color-field" style={{backgroundColor: lightBlue[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="cyan">
                                        <div className="color-field" style={{backgroundColor: cyan[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="teal">
                                        <div className="color-field" style={{backgroundColor: teal[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="green">
                                        <div className="color-field" style={{backgroundColor: green[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="lightGreen">
                                        <div className="color-field" style={{backgroundColor: lightGreen[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="lime">
                                        <div className="color-field" style={{backgroundColor: lime[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="yellow">
                                        <div className="color-field" style={{backgroundColor: yellow[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="amber">
                                        <div className="color-field" style={{backgroundColor: amber[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="orange">
                                        <div className="color-field" style={{backgroundColor: orange[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="deepOrange">
                                        <div className="color-field" style={{backgroundColor: deepOrange[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="brown">
                                        <div className="color-field" style={{backgroundColor: brown[500]}}></div>
                                    </ToggleButton>

                                </ToggleButtonGroup>




                                <h4>Sekundärfarbe</h4>
                                <ToggleButtonGroup
                                    value={props.userProfile.secondaryColor}
                                    exclusive
                                    onChange={(event, newSecondary)=> {
                                            updateUserProfile({secondaryColor: newSecondary});
                                        }
                                    }
                                >
                                    <ToggleButton value="red">
                                        <div className="color-field" style={{backgroundColor: red[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="pink">
                                        <div className="color-field" style={{backgroundColor: pink[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="purple">
                                        <div className="color-field" style={{backgroundColor: purple[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="deepPurple">
                                        <div className="color-field" style={{backgroundColor: deepPurple[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="indigo">
                                        <div className="color-field" style={{backgroundColor: indigo[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="blue">
                                        <div className="color-field" style={{backgroundColor: blue[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="lightBlue">
                                        <div className="color-field" style={{backgroundColor: lightBlue[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="cyan">
                                        <div className="color-field" style={{backgroundColor: cyan[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="teal">
                                        <div className="color-field" style={{backgroundColor: teal[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="green">
                                        <div className="color-field" style={{backgroundColor: green[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="lightGreen">
                                        <div className="color-field" style={{backgroundColor: lightGreen[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="lime">
                                        <div className="color-field" style={{backgroundColor: lime[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="yellow">
                                        <div className="color-field" style={{backgroundColor: yellow[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="amber">
                                        <div className="color-field" style={{backgroundColor: amber[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="orange">
                                        <div className="color-field" style={{backgroundColor: orange[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="deepOrange">
                                        <div className="color-field" style={{backgroundColor: deepOrange[500]}}></div>
                                    </ToggleButton>
                                    <ToggleButton value="brown">
                                        <div className="color-field" style={{backgroundColor: brown[500]}}></div>
                                    </ToggleButton>

                                </ToggleButtonGroup>


                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        
    )
}
