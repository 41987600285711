import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import {firebaseHandle} from '../user_accounts/Authentification';
import {Edit, Delete, Add} from '@material-ui/icons';
import CreateEditBox from './CreateEditBox';

export default function BoxAdmin() {


    const [userBoxes, setUserBoxes] = useState([]);
    const [showCreateEditBox, setShowCreateEditBox] = useState(false);
    const [editBox, setEditBox] = useState({title: '', icon:  '', color: '', link: '', external: false});

    useEffect(() => {

        firebaseHandle.db.collection("UserDashboardBox").onSnapshot((UserDashboardBoxes) => {
            const boxes = [];
            UserDashboardBoxes.forEach((box) => {
                const data = box.data();
                data.id = box.id;
                data.edit = <IconButton onClick={()=>{handleEdit(data)}}><Edit /></IconButton>;
                data.delete = <IconButton onClick={()=>{handleDelete(box.id)}}><Delete /></IconButton>;
                boxes.push(data);
            });
            setUserBoxes(boxes);
        });

    }, []);


    const handleEdit = (box) => {
        setEditBox(box);
        setShowCreateEditBox(true);
    }

    const handleDelete = (id) => {
        firebaseHandle.db.doc('/UserDashboardBox/'+id).delete();
    }

    const handleCloseCreateEdit = () => {
        setEditBox({severity: '', icon:  '', text: '', id: ''});
        setShowCreateEditBox(false);
    }

    return (
        <>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>Titel</TableCell>
                    <TableCell>Icon</TableCell>
                    <TableCell>Farbe</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Neues Fenster</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {userBoxes.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.title}</TableCell>
                        <TableCell><i className="material-icons">{row.icon}</i></TableCell>
                        <TableCell><span style={{color: row.color}}>{row.color}</span></TableCell>
                        <TableCell>{row.link}</TableCell>
                        <TableCell>{row.external ? 'Ja' : 'Nein'}</TableCell>
                        <TableCell>{row.edit}</TableCell>
                        <TableCell>{row.delete}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

            <Fab color="secondary" style={{position: 'fixed', bottom: 20, right: 20}} onClick={() => {setShowCreateEditBox(true)}}>
                <Add />
            </Fab>

            <CreateEditBox show={showCreateEditBox} close={handleCloseCreateEdit} editBox={editBox} />
        </>
    )
}
