import React, {useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { firebaseHandle } from '../user_accounts/Authentification';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function CreateTicket(props) {

    const [subject, setSubject] = useState('');
    const [what, setWhat] = useState('');
    const [why, setWhy] = useState('');
    const [who, setWho] = useState(null);

    const [subjectError, setSubjectError] = useState(false);
    const [whatError, setWhatError] = useState(false);
    const [whyError, setWhyError] = useState(false);
    const [whoError, setWhoError] = useState(false);


    const sendTicket = () => {

        setSubjectError(false);
        setWhatError(false);
        setWhyError(false);
        setWhoError(false);

        let error = false;
        if(subject === ''){
            setSubjectError(true);
            error = true;
        }
        if(what === ''){
            setWhatError(true);
            error = true;
        }
        if(why === ''){
            setWhyError(true);
            error = true;
        }
        if(who === null){
            setWhoError(true);
            error = true;
        }
        
        if(!error)
        {

            firebaseHandle.db.collection('EasyTicketStates').add({
                crdate: new Date(),
                state: 'offen',
                user: props.userProfileRef
            }).then((state) => {
                firebaseHandle.db.collection('EasyTicket').add({
                    crdate: new Date(),
                    subject: subject,
                    what: what,
                    why: why,
                    notify: [who.ref],
                    state: [state],
                    deadline: null,
                    comment: '',
                    creator: props.userProfileRef,
                    reviser: who.data().firstName + ' ' + who.data().lastName

                }).then((ticket) => {
                    props.userProfileRef.update({
                        easyTickets: firebaseHandle.fieldValue.arrayUnion(ticket)
                    });
                    who.ref.update({
                        easyTicketInquiries: firebaseHandle.fieldValue.arrayUnion(ticket)
                    })
                });

            });

            props.close();

            setSubject('');
            setWhat('');
            setWhy('');
            setWho(null);
        }

    }

    
    return (
        <Dialog onClose={props.close} open={props.show} className="ticketDialog" >
            <IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={props.close}>
                    <CloseIcon />
            </IconButton>
            <DialogTitle style={{width: 'calc( 100% - 40px )'}}>Easy-Ticket erstellen</DialogTitle>
            <TextField
                label="Betreff"
                error={subjectError}
                variant="outlined"
                value={subject}
                onChange={(evt) => {setSubject(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Was wird gebraucht"
                error={whatError}
                multiline
                rows="3"
                variant="outlined"
                value={what}
                onChange={(evt) => {setWhat(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <TextField
                label="Warum"
                error={whyError}
                multiline
                rows="3"
                variant="outlined"
                value={why}
                onChange={(evt) => {setWhy(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <Autocomplete
                options={props.receivers}
                getOptionLabel={option => option.data().firstName + ' ' + option.data().lastName}
                style={{margin: '10px 20px' }}
                value={who}
                onChange={(event, newValue) => {
                    setWho(newValue);
                }}
                renderInput={params => <TextField {...params} error={whoError} label="Senden an" variant="outlined" style={{ width: '100%' }} />}
            />

            <Button
                variant="contained"
                color="primary"
                style={{float: 'right'}}
                onClick={sendTicket}
            >
                Beantragen
            </Button>
        </Dialog>
    )
}
