import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firebaseHandle } from '../user_accounts/Authentification';
import Link from '@material-ui/core/Link';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import "moment/locale/de";
import LinearProgress from '@material-ui/core/LinearProgress';

moment.locale("de");

export default function CreateEditBox(props) {

    const [editId, setEditId] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setEditId(props.manual.id === undefined ? '' : props.manual.id);
        setTitle(props.manual.title);
        setFile(props.manual.file);
    }, [props.manual])

    const handleSave = () => {

        if(editId === ''){
            firebaseHandle.db.collection("Manual").add({title: title, file: 'manuals/' + file.name});
        }
        else{
            firebaseHandle.db.doc("Manual/"+editId).update({title: title, file: 'manuals/' + file.name});
        }
        handleClose();
    }

    const handleClose = () => {
        props.close();
        setEditId('');
        setTitle('');
        setFile('');
    }

    const uploadFile = (file) => {
        const uploadTask = firebaseHandle.storageRef.child('manuals/' + file.name).put(file);
        uploadTask.on('state_changed', function(snapshot){
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress < 0.1 ? 0.1 : progress);
        }, function(error) {
            console.log(error);
            setProgress(0);
        }, function() {
            // Handle successful uploads on complete
            setProgress(0);
        });

    }

    const uploadButton = (progress) => {

        if(progress === 0)
        {
            return (
                <div style={{margin: '10px 20px'}}>
                    
                    <input
                        accept="application/pdf"
                        id="contained-button-file"
                        type="file"
                        onChange={(e)=> { 
                            setFile(e.target.files[0]);
                            uploadFile(e.target.files[0]);
                       }}
                       style={{display: 'none'}}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="secondary" component="div" style={{marginRight: '15px'}}>
                            Upload
                        </Button>
                        {file.name}
                    </label>
                </div>
            );
        }
        else
        {
            return <LinearProgress variant="determinate" value={progress} style={{margin: '10px 20px'}}/>
        }

    }

    return (
        <Dialog onClose={handleClose} open={props.show}>
            <DialogTitle>Anleitung erstellen</DialogTitle>

            <TextField
                label="Titel"
                variant="outlined"
                value={title}
                onChange={(evt)=>{setTitle(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />

            
            {uploadButton(progress)}
            
            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{float: 'right'}}
            >
                { editId === '' ? 'Erstellen' : 'Ändern' }
            </Button>

        </Dialog>
    )
}