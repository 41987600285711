import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { getUser } from './Authentification';
import Snackbar from '@material-ui/core/Snackbar';

export default function NotValidated() {

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);


    const resendMail = () =>
    {
        const firebaseUser = getUser();
        firebaseUser.sendEmailVerification();
        setSnackbarOpen(true);

        setTimeout(() => {
            setSnackbarOpen(false);
        }, 3000);
    }

    return (
        <>
            <Grid container spacing={0} justify="center">
                <Grid item xs={12} sm={10} md={6} lg={3}>
                    <Paper>
                        <Alert severity="info">
                            <AlertTitle>E-Mail-Adresse noch nicht bestätigt</AlertTitle>
                            Bitte bestätigen Sie Ihre E-Mail-Adresse indem Sie dem Link in der Mail folgen.
                        </Alert>
                        <Button onClick={resendMail}>E-Mail erneut senden</Button>
                    </Paper>
                </Grid>
            </Grid>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => {setSnackbarOpen(false)}}>
                <Alert onClose={() => {setSnackbarOpen(false)}} severity="success">
                    E-Mail wurde erneut gesendet.
                </Alert>
            </Snackbar>
        </>
    )
}
