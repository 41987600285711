import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-database";
import 'firebase/storage';
const FieldValue = require('firebase').firestore.FieldValue;

const config = {
  apiKey: "AIzaSyA2zHlYvz1F5smdG2U_7yqOsnP9yU55W7g",
  authDomain: "intranet-roehm-medien.firebaseapp.com",
  databaseURL: "https://intranet-roehm-medien.firebaseio.com",
  projectId: "intranet-roehm-medien",
  storageBucket: "intranet-roehm-medien.appspot.com",
  messagingSenderId: "292996246121",
  appId: "1:292996246121:web:8047393ef32ae5741790f8",
  measurementId: "G-ST72Y0H5EX"
};

export default class Firebase {
  constructor() {
  app.initializeApp(config);
  this.fieldValue = FieldValue;
  this.auth = app.auth();
  this.db = app.firestore();
  this.rtdb = app.database();
  this.storage = app.storage();
  this.storageRef = this.storage.ref();
}

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

}