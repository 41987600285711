import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

function PrivateRoute(props) {


    const route = () => 
    {

        if(props.user !== null) 
        {
            if(props.role !== undefined && props.userProfile.roles !== undefined){        
                if(props.userProfile.roles.includes(props.role)){
                    return <Route {...props} />
                }
                else {
                    return <Redirect to='/' />
                }
            }
            else{
                return <Route {...props} />
            }    
        }
        else if(props.location.pathname === props.path){
            return <Redirect to='/' />
        }
        else{
            return '';
        }
        
    }
    


    return (
        <>
            {route()}
        </>
    )
}

export default withRouter(PrivateRoute)