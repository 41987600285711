import React, {useState, useEffect} from 'react';
import './App.css';
import AppBar from './components/appBar/AppBar';

import { firebaseHandle } from './components/user_accounts/Authentification';

import SignUp from './components/user_accounts/SignUp';
import Login from './components/user_accounts/Login';
import NotValidated from './components/user_accounts/NotValidated';
import Dashboard from './components/dashboard/Dashboard';
import Kontakte from './components/kontakte/Kontakte';
import ResetPassword from './components/user_accounts/ResetPassword';
import Settings from './components/user_settings/Settings';
import EmailUpdate from './components/user_settings/EmailUpdate';
import MeetingRooms from './components/meeting_rooms/MeetingRooms';
import InfoAdmin from './components/info_admin/InfoAdmin';
import EasyTicket from './components/easy_ticket/EasyTicket';
import BoxAdmin from './components/box_admin/BoxAdmin';
import SelectBoxes from './components/select_boxes/SelectBoxes';
import RomanVillage from './components/roman_village/RomanVillage';
import Manual from './components/manual/Manual';
import PrivateDocuments from './components/private_documents/PrivateDocuments';

import { BrowserRouter, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';

import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import brown from '@material-ui/core/colors/brown';


function App() {

  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState([]);
  const [userProfileRef, setUserProfileRef] = useState({});
  const [theme, setTheme] = useState(
    {
      palette: {
        type: 'dark',
        primary: blue,
        secondary: purple,
      }
    }
  )

  const colors = {
    red: red,
    pink: pink,
    purple: purple,
    deepPurple: deepPurple,
    indigo: indigo,
    blue: blue,
    lightBlue: lightBlue,
    cyan: cyan,
    teal: teal,
    green: green,
    lightGreen: lightGreen,
    lime: lime,
    yellow: yellow,
    amber: amber,
    orange: orange,
    deepOrange: deepOrange,
    brown: brown
  }

  useEffect(() => {
	  if(user !== null)
	  {
      firebaseHandle.db.collection("Users").doc(user.uid).onSnapshot(function(userProfile) {
        setUserProfile(userProfile.data());
        setUserProfileRef(userProfile.ref);
        setTheme({
        palette: {
          type: userProfile.data().darkMode ? 'dark' : 'light',
          primary: colors[userProfile.data().primaryColor],
          secondary: colors[userProfile.data().secondaryColor],
        }
        });
      });
	  }
  }, [user])

  firebaseHandle.auth.onAuthStateChanged(function(user) {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
  });

  const DashboardRoute = () => {

    if(!user)
    {
      return <Route exact path="/" component={Login} />
    }
    else if (!user.emailVerified)
    {
      // Neu laden bis der Benutzer seine E-Mail verifiziert hat
      const interval = setInterval(()=>{
        firebaseHandle.auth.currentUser.reload().then(()=>{
          if(firebaseHandle.auth.currentUser.emailVerified)
          {
            clearInterval(interval);
            setUser(null);
            setUser(firebaseHandle.auth.currentUser);
          }
        });

      }, 5000)

      return <Route exact path="/" component={NotValidated} />
    }
    else
    {
      return <Route exact path="/" render={(props) => <Dashboard userProfile={userProfile} userProfileRef={userProfileRef} />} />
    }

  }
  

  const muiTheme = createMuiTheme(theme);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppBar user={user} userProfile={userProfile} />
        <div style={{height: '64px'}}></div>
        <DashboardRoute />
        <Route path="/signup" component={SignUp} />
        <Route path="/passwort-vergessen" component={ResetPassword} />
        <Route path="/email-update" component={EmailUpdate} />
        <Route path="/meetingräume" render={() => <MeetingRooms userProfile={userProfile} user={user}/>}/>
        <PrivateRoute user={user} path="/kontakte" render={() => <Kontakte userProfile={userProfile} />}/>
        <Route user={user} path="/privat" render={() => <PrivateDocuments userProfile={userProfile} />}/>
        <PrivateRoute user={user} path="/easy-ticket" render={() => <EasyTicket user={user} easyTickets={userProfile.easyTickets} easyTicketInquiries={userProfile.easyTicketInquiries} userProfileRef={userProfileRef} forceUpdate={userProfile.forceUpdate} />}/>
        <PrivateRoute user={user} userProfile={userProfile} role="box-admin" path="/box-admin" component={BoxAdmin}/>
        <PrivateRoute user={user} userProfile={userProfile} role="info-admin" path="/info-admin" component={InfoAdmin}/>
        <PrivateRoute user={user} path="/boxauswahl" render={() => <SelectBoxes userProfile={userProfile} userId={user.uid} />}/>
        <PrivateRoute user={user} path="/röhmisches-dorf" render={() => <RomanVillage userProfile={userProfile} />}/>
        <PrivateRoute user={user} path="/anleitungen" render={() => <Manual userProfile={userProfile} />}/>
        <PrivateRoute user={user} path="/einstellungen" render={() => <Settings key={userProfile.firstName} userProfile={userProfile} userProfileRef={userProfileRef} />} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
