import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import LastPageIcon from '@material-ui/icons/LastPage'
import WidgetsIcon from '@material-ui/icons/Widgets';
import { Link as RouterLink } from 'react-router-dom';
import { firebaseHandle } from '../user_accounts/Authentification';



const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    if(props.user !== null)
    {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let listItemBoxAuswahl = '';
  if(Object.keys(props.userProfile).length > 0 && !props.userProfile.roles.includes('gast'))
  {
    listItemBoxAuswahl = (
      <ListItem button component={RouterLink} to="/boxauswahl" key='selectBoxes' onClick={handleDrawerClose} >
          <ListItemIcon><WidgetsIcon /></ListItemIcon>
            <ListItemText primary='Box-Auswahl' />
      </ListItem>
    )
  }

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to='/' style={{marginTop: '10px'}}>
            <img className="logo" src={process.env.PUBLIC_URL + '/logo_white.png'} alt='Röhm Medien' />
          </RouterLink>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <ListItem button component={RouterLink} to="/" key='dashboard' onClick={handleDrawerClose} >
            <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItem>

            {listItemBoxAuswahl}

            <ListItem button component={RouterLink} to="/einstellungen" key='settings' onClick={handleDrawerClose} >
            <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary='Einstellungen' />
            </ListItem>

            <div style={{flexGrow: 1}}></div>
            <ListItem button key='logout' onClick={()=>{firebaseHandle.auth.signOut(); handleDrawerClose()}} >
            <ListItemIcon><LastPageIcon /></ListItemIcon>
              <ListItemText primary='Abmelden' />
            </ListItem>
        </List>
      </Drawer>
    </>
  );
}
