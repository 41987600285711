import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import {firebaseHandle} from '../user_accounts/Authentification';
import './SelectBoxes.css';

export default function SelectBoxes(props) {


    const [boxes, setBoxes] = useState([]);


    const handleClick = (box, active) => {

        if(active){
            firebaseHandle.db.doc('Users/'+props.userId).update({
                userDashboardBoxes: firebaseHandle.fieldValue.arrayRemove(box.ref)
            });
        }
        else{
            firebaseHandle.db.doc('Users/'+props.userId).update({
                userDashboardBoxes: firebaseHandle.fieldValue.arrayUnion(box.ref)
            });
        }

    }


    useEffect(() => {
    
        if(props.userProfile)
        {
            const tmpBoxes = []
            firebaseHandle.db.collection('UserDashboardBox').get().then((allBoxes)=>{
                allBoxes.forEach(box => {
                    const data = box.data();
                    const active = props.userProfile.userDashboardBoxes.filter(userDashboardBox => userDashboardBox.id === box.ref.id).length > 0;
                    tmpBoxes.push(
                        <Grid item xs={6} sm={3} md={2} lg={1} key={box.id} >
                            <div style={active ? {backgroundColor: data.color} : {backgroundColor: 'rgba(120, 120, 120, 0.7)'}} className="select-box" onClick={()=>{handleClick(box, active)}}>
                                <div>
                                    <h4>{data.title}</h4>
                                    <Icon children={data.icon} />
                                </div>
                            </div>
                        </Grid>
                    )
                });
                setBoxes(tmpBoxes);
            });
        }
        
    }, [props.userProfile])


    return (
        <Grid container spacing={0} justify="flex-start" alignItems="stretch">
            {boxes}
        </Grid>
    )
}
