import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { loginUser } from '../user_accounts/Authentification';
import {updateUserProfile} from '../user_accounts/Authentification';

export default function EmailUpdate() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [alert, setAlert] = useState('');


    const submitNewEmail = (event) => {
        event.preventDefault();
        setAlert('');
        loginUser(email, password).then(function(userCredential) {
            userCredential.user.updateEmail(newEmail).then(function(){
                updateUserProfile({'email': newEmail}).then(function(){
                    userCredential.user.sendEmailVerification().then(function() {
                        window.location.href = '/';
                    }).catch(function(error) {
                        setAlert(<Alert style={{marginBottom: '25px'}} severity="error">error</Alert>)
                    });
                })
            }).catch(function(error) {
                setAlert(<Alert style={{marginBottom: '25px'}} severity="error">error</Alert>)
            });
        }).catch(function(error) {
            setAlert(<Alert style={{marginBottom: '25px'}} severity="error">Aktuelle E-Mail oder Passwort falsch</Alert>)
        });
    }


    return (
        <Grid container spacing={0} justify="center">
            <Grid item xs={12} sm={10} md={6} lg={3}>
                <Paper style={{padding: '20px'}} elevation={15} >
                    <form id="newEmailForm" noValidate autoComplete="on" onSubmit={submitNewEmail}>
                        {alert}
                        <TextField label="Aktuelle E-Mail-Adresse" variant="outlined" value={email} style={{'margin-bottom': '20px', width: '100%'}} onChange={(event) => {setEmail(event.target.value)}} />
                        <TextField label="Passwort" variant="outlined" type="password" value={password} style={{'margin-bottom': '20px', width: '100%'}} onChange={(event) => {setPassword(event.target.value)}} />
                        <TextField label="Neue E-Mail-Adresse" variant="outlined" value={newEmail} style={{'margin-bottom': '20px', width: '100%'}} onChange={(event) => {setNewEmail(event.target.value)}} />
                        <Button variant="contained" color="primary" type="submit" style={{float: 'right'}}>
                            E-Mail Ändern
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
        
    )
}
