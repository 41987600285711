import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {updateUserProfile} from '../user_accounts/Authentification';
import EditSaveButton from './EditSaveButton';



export default function Profile(props) {


    const [value, setValue] = useState(props.value);
    const [isEnabled, setIsEnabled] = useState(false);


    return (
        <>
            <TextField disabled={!isEnabled} label={props.label} value={value} onChange={(event)=>{setValue(event.target.value)}} /> <EditSaveButton save={()=>{updateUserProfile({[props.field]: value})}} isEnabled={isEnabled} onClick={()=>{setIsEnabled(!isEnabled)}} />
        </>
    )
}
