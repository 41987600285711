import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firebaseHandle } from '../user_accounts/Authentification';
import Link from '@material-ui/core/Link';



export default function CreateEditBox(props) {

    const [editId, setEditId] = useState('');
    const [icon, setIcon] = useState('');
    const [title, setTitle] = useState('');
    const [color, setColor] = useState('');
    const [link, setLink] = useState('');
    const [external, setExternal] = useState(false);

    useEffect(() => {
        setEditId(props.editBox.id === undefined ? '' : props.editBox.id);
        setIcon(props.editBox.icon);
        setTitle(props.editBox.title);
        setColor(props.editBox.color);
        setLink(props.editBox.link);
        setExternal(props.editBox.external);
    }, [props.editBox])

    const handleSave = () => {

        if(editId === ''){
            firebaseHandle.db.collection("UserDashboardBox").add({title: title, icon: icon, color: color, link: link, external: external});
        }
        else{
            firebaseHandle.db.doc("UserDashboardBox/"+editId).update({title: title, icon: icon, color: color, link: link, external: external});
        }
        handleClose();
    }


    const handleClose = () => {
        props.close();
        setEditId('');
        setTitle('');
        setIcon('');
        setColor('');
        setLink('');
        setExternal(false);
    }

    return (
        <Dialog onClose={handleClose} open={props.show}>
            <DialogTitle>Info erstellen</DialogTitle>

            <TextField
                label="Titel"
                variant="outlined"
                value={title}
                onChange={(evt)=>{setTitle(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />

            <TextField
                label="Farbe"
                variant="outlined"
                value={color}
                onChange={(evt)=>{setColor(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <Link href="https://material-ui.com/customization/color/" target="_blank" style={{margin: '0 0 10px 20px'}}>Material Design Farben</Link>

            <TextField
                label="Icon"
                variant="outlined"
                value={icon}
                onChange={(evt)=>{setIcon(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />
            <Link href="https://material.io/resources/icons/" target="_blank" style={{margin: '0 0 10px 20px'}}>Material Design Icons</Link>

            <TextField
                label="Link"
                variant="outlined"
                value={link}
                onChange={(evt)=>{setLink(evt.target.value)}}
                style={{margin: '10px 20px'}}
            />

            <FormControl style={{margin: '10px 20px', minWidth: 315}}>
                <InputLabel id="externalLabel">Neues Fenster</InputLabel>
                <Select
                    labelId="externalLabel"
                    value={external}
                    onChange={(evt)=>{setExternal(evt.target.value)}}
                >
                    <MenuItem value={false}>Nein</MenuItem>
                    <MenuItem value={true}>Ja</MenuItem>
                </Select>
            </FormControl>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                style={{float: 'right'}}
            >
                { editId === '' ? 'Erstellen' : 'Ändern' }
            </Button>

        </Dialog>
    )
}