import React, { PureComponent  } from 'react'
import {firebaseHandle} from '../user_accounts/Authentification';
import Table from './Table';

import NewAppointment from "./NewAppointment";



export default class Scheduler extends PureComponent  {

    constructor(props) {
        super(props);

        this.state = {
            buchungen: {}
        }

        this.fetchAppointments = this.fetchAppointments.bind(this);
    }

    componentDidMount() 
    {
        this.fetchAppointments();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDate !== prevProps.selectedDate) {
            this.fetchAppointments();
        }
    }


    fetchAppointments()
    {
        firebaseHandle.rtdb.ref('buchungen/'+ this.props.selectedDate.format('YYYY-MM-DD')).once('value').then(snapshot => {
            this.setState({ buchungen: snapshot.val() });
        })
    }


    render() {

        return (
            <>
                <Table buchungen={this.state.buchungen} selectedDate={this.props.selectedDate} fetchAppointments={this.fetchAppointments} user={this.props.user} />
                <NewAppointment buchungen={this.state.buchungen} selectedDate={this.props.selectedDate} fetchAppointments={this.fetchAppointments} userProfile={this.props.userProfile} user={this.props.user} />
            </>
        )
    }
}