import React, { useState } from 'react';
import './SignUp.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { signUpUser } from './Authentification';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import "moment/locale/de";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

moment.locale("de");

export default function SignUp() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [mobilePhoneBusiness, setMobilePhoneBusiness] = useState('');
    const [firm, setFirm] = useState('');
    const [job, setJob] = useState('');
    const [birthday, setBirthday] = React.useState(new moment());

    const [emailError, setEmailError] = useState({error:false,helperText:''});
    const [passwordError, setPasswordError] = useState({error:false,helperText:''});
    const [password2Error, setPassword2Error] = useState({error:false,helperText:''});
    const [firstNameError, setFirstNameError] = useState({error:false,helperText:''});
    const [lastNameError, setLastNameError] = useState({error:false,helperText:''});
    const [phoneError, setPhoneError] = useState({error:false,helperText:''});
    const [mobilePhoneError, setMobilePhoneError] = useState({error:false,helperText:''});
    const [firmError, setFirmError] = useState({error:false,helperText:''});
    

    const submitSignUp = (event) => {
        event.preventDefault();

        setEmailError({error: false, helperText:''});
        setPasswordError({error: false, helperText:''});
        setPassword2Error({error: false, helperText:''});
        setFirstNameError({error: false, helperText:''});
        setLastNameError({error: false, helperText:''});
        setPhoneError({error: false, helperText:''});
        setMobilePhoneError({error: false, helperText:''});
        setFirmError({error: false, helperText:''});

        let canSend = true;


        if( !(email.split('@').pop() === 'szbz.de' 
            || email.split('@').pop() === 'roehmszbz.de' 
            || email.split('@').pop() === 'sportverlag-sindelfingen.de' 
            || email.split('@').pop() === 'dzb-bb.de' 
            || email.split('@').pop() === 'bwpost-bb.de'
            || email.split('@').pop() === 'quicklogistics.de'
            || email.split('@').pop() === 'abenteuer-magazine.de' 
            || email.split('@').pop() === 'roehmgruppe.de'
            || email.split('@').pop() === 'dzs-sifi.de'
            || email.split('@').pop() === 'buchhaltung-komplett.de'))
        {
            canSend = false;
            setEmailError({error: true, helperText:'Keine Unternehmens E-Mail Adresse'});
        }

        if(!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
        {
            canSend = false;
            setEmailError({error: true, helperText:'Keine gültige E-Mail Adresse'});
        }
            

        if(password.length < 6)
        {
            canSend = false;
            setPasswordError({error: true, helperText:'Mindestens 6 Zeichen'});
        }

        if(password !== password2)
        {
            canSend = false;
            setPassword2Error({error: true, helperText:'Passwörter stimmen nicht überein'});
        }


        if(!firstName)
        {
            canSend = false;
            setFirstNameError({error: true, helperText:'Pflichtfeld'});
        }

        if(!lastName)
        {
            canSend = false;
            setLastNameError({error: true, helperText:'Pflichtfeld'});
        }

        if(!phone)
        {
            canSend = false;
            setPhoneError({error: true, helperText:'Pflichtfeld'});
        }

        //if(!mobilePhone)
        //{
        //    canSend = false;
        //    setMobilePhoneError({error: true, helperText:'Pflichtfeld'});
        //}

        if(!firm)
        {
            canSend = false;
            setFirmError({error: true, helperText:'Pflichtfeld'});
        }

        

        if(canSend)
        {
            if( !(email.split('@').pop() === 'szbz.de' 
            || email.split('@').pop() === 'roehmszbz.de' 
            || email.split('@').pop() === 'sportverlag-sindelfingen.de' 
            || email.split('@').pop() === 'dzb-bb.de' 
            || email.split('@').pop() === 'bwpost-bb.de'
            || email.split('@').pop() === 'quicklogistics.de'
            || email.split('@').pop() === 'abenteuer-magazine.de' 
            || email.split('@').pop() === 'roehmgruppe.de'
            || email.split('@').pop() === 'dzs-sifi.de'))
            {
                signUpUser({
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    mobilePhone: mobilePhone,
                    mobilePhoneBusiness: mobilePhoneBusiness,
                    birthday: birthday.unix(),
                    job: job,
                    firm: firm,
                    roles: ['gast']
                });
            }
            else
            {
                signUpUser({
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    mobilePhone: mobilePhone,
                    mobilePhoneBusiness: mobilePhoneBusiness,
                    birthday: birthday.unix(),
                    job: job,
                    firm: firm
                });
            }

            
        }

    }


    return (

        <Grid container spacing={0} justify="center">
            <Grid item xs={12} sm={10} md={6} lg={3}>
                <Paper style={{padding: '20px'}} elevation={15} >
                <form className="signUpForm" noValidate autoComplete="on" onSubmit={submitSignUp}>
                    <TextField id="email" label="E-Mail" value={email} onChange={(event) => {setEmail(event.target.value)}} error={emailError.error} helperText={emailError.helperText} />
                    <TextField id="password" type="password" label="Passwort" value={password} onChange={(event) => {setPassword(event.target.value)}} error={passwordError.error} helperText={passwordError.helperText} />
                    <TextField id="password2" type="password" label="Passwort wiederholen" value={password2} onChange={(event) => {setPassword2(event.target.value)}} error={password2Error.error} helperText={password2Error.helperText} />
                    <TextField id="firstName" label="Vorname" value={firstName} onChange={(event) => {setFirstName(event.target.value)}} error={firstNameError.error} helperText={firstNameError.helperText} />
                    <TextField id="lastName" label="Nachname" value={lastName} onChange={(event) => {setLastName(event.target.value)}} error={lastNameError.error} helperText={lastNameError.helperText} />
                    {/* profileImage */}
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Geburtstag"
                            format="DD/MM/YYYY"
                            value={birthday}
                            onChange={(date) => {setBirthday(date)}} 
                            />
                    </MuiPickersUtilsProvider>

                    <TextField id="phone" label="Telefon" value={phone} onChange={(event) => {setPhone(event.target.value)}} error={phoneError.error} helperText={phoneError.helperText} />
                    <TextField id="mobilePhone" label="Mobiltelefon Privat (optional)" value={mobilePhone} onChange={(event) => {setMobilePhone(event.target.value)}} error={mobilePhoneError.error} helperText={mobilePhoneError.helperText} />
                    <TextField id="mobilePhoneBusiness" label="Mobiltelefon geschäftlich (optional)" value={mobilePhoneBusiness} onChange={(event) => {setMobilePhoneBusiness(event.target.value)}} />

                    <FormControl error={firmError.error}>
                        <InputLabel id="firm-label">Firma</InputLabel>
                        <Select
                            labelId="firm-label"
                            value={firm}
                            onChange={(event) => {setFirm(event.target.value)}}
                        >
                        <MenuItem value="BW-Post">BW-Post</MenuItem>
                        <MenuItem value="DZB">DZB</MenuItem>
                        <MenuItem value="DZS">DZS</MenuItem>
                        <MenuItem value="Quick-Logistics">Quick-Logistics</MenuItem>
                        <MenuItem value="RMS">RMS</MenuItem>
                        <MenuItem value="RTM">RTM</MenuItem>
                        <MenuItem value="RVM">RVM</MenuItem>
                        <MenuItem value="Röhm Buch und Büro Leonberg">Röhm Buch und Büro Leonberg</MenuItem>
                        <MenuItem value="Röhm Buch und Büro Sindelfingen">Röhm Buch und Büro Sindelfingen</MenuItem>
                        <MenuItem value="Röhm KG">Röhm KG</MenuItem>
                        <MenuItem value="Röhm Medien">Röhm Medien</MenuItem>
                        <MenuItem value="Sportverlag">Sportverlag</MenuItem>
                        <MenuItem value="WBZV">WBZV</MenuItem>
                        <MenuItem value="Zeitgeist">Zeitgeist</MenuItem>
                        <MenuItem value="Z-Druck">Z-Druck</MenuItem>
                        <MenuItem value="Buchhaltung-Komplett">Buchhaltung-Komplett</MenuItem>
                        </Select>
                        <FormHelperText>{firmError.helperText}</FormHelperText>
                    </FormControl>


                    <TextField id="job" label="Tätigkeit (optional)" value={job} onChange={(event) => {setJob(event.target.value)}} />

                    <Button variant="contained" color="primary" type="submit" style={{float: 'right'}}>
                        Registrieren
                    </Button>
                </form>
                </Paper>
                <br />
                <Link component={RouterLink} to="/" style={{marginRight: '10px'}}>Zurück zur Anmeldung</Link>
            </Grid>
        </Grid>

    )
}
