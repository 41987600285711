import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Edit, Save } from '@material-ui/icons';



export default function Profile(props) {


    const [icon, setIcon] = useState(<Edit />);

    const handleClick = () => {
        if(props.isEnabled)
        {
            props.save();
            setIcon(<Edit />);
        }
        else
        {   
            setIcon(<Save />);
        }
    }


    return (
        <>
            <IconButton onClick={()=>{handleClick(); props.onClick()}}>
                {icon}
            </IconButton>
        </>
    )
}
