import React, { PureComponent  } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip';
import { isMobile } from "react-device-detect";

import {firebaseHandle} from '../user_accounts/Authentification';


import './Table.css';



export default class SimpleTable extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            deleteId: null, 
            deleteTitle: null,
            deleteName: null
        }

    }

    createData(uhrzeit, raum1, raum2, raum21 ,raum3) {
        return { uhrzeit, raum1, raum2, raum21, raum3 };
    }

    tableCellClass = "szbz-tableCell";

    handleDelete()
    {
        firebaseHandle.rtdb.ref('buchungen/'+ this.props.selectedDate.format('YYYY-MM-DD') + '/' + this.state.deleteId).remove();
        delete this.props.buchungen[this.state.deleteId];

        this.handleDialogClose();
    }

    handleBulkDelete()
    {

        firebaseHandle.rtdb.ref('buchungen/').once('value').then(snapshot => {
            const days = snapshot.val();
            for (const day in days) {
                for (const key in days[day]) {
                    const buchung = days[day][key];
                    
                    if(buchung.titel === this.state.deleteTitle && buchung.user === this.props.user.uid)
                    {
                        firebaseHandle.rtdb.ref('buchungen/'+ day + '/' + key).remove();
                    }
                }
            }
            delete this.props.buchungen[this.state.deleteId];
            this.handleDialogClose();
        })
    }

    handleDialogOpen = (id) => {
        this.setState({open: true, deleteId: id, deleteTitle: this.props.buchungen[id].titel, deleteName: this.props.buchungen[id].name});
    };

    handleDialogClose = () => {
        this.setState({open: false, deleteId: null, deleteTitle: null, deleteName: null});
    };

    render()
    {

        const rows = [];

        for(let i=6; i < 20; i++)
        {
            let raum1 = [];
            let raum2 = [];
            let raum21 = [];
            let raum3 = [];
            

            for (const key in this.props.buchungen) {

                const buchung = this.props.buchungen[key];

                const von = new Date(buchung.von);
                const bis = new Date(buchung.bis);

                if(von.getHours() === i)
                {
                    const diff = Math.abs(bis - von);
                    const minutes = Math.floor((diff/1000)/60);
                    const height = (minutes / 60) * 53;
                    const top = (von.getMinutes() / 60) * 53;

                    const classList = ["szbz-appointment"];

                    if(height < 30)
                    {
                        classList.push("collapsed");
                    }


                    const longText = moment(von).format('HH:mm')+" - "+moment(bis).format('HH:mm')+" Uhr | "+buchung.titel+" | "+buchung.name;
                    let text;
                    if(isMobile)
                    {
                        text = <p class="no-select">{moment(von).format('HH:mm')} - {moment(bis).format('HH:mm')}</p>;
                    }
                    else
                    {
                        text = <p>{longText}</p>;
                    }
                    

                    switch (this.props.buchungen[key].raum) {
                        case 1:
                            raum1.push(
                                <Tooltip key={key} disableHoverListener disableFocusListener title={longText}>
                                    <Paper className={classList.join(' ')} style={{backgroundColor: this.props.buchungen[key].farbe,height: height,top: top}} >
                                        {text}
                                        {this.props.user && this.props.buchungen[key].user === this.props.user.uid ? <Icon className="szbz-delete-appointment" onClick={()=> this.handleDialogOpen(key)}>delete</Icon> : ''}
                                    </Paper>
                                </Tooltip>
                            );
                        break;

                        case 2:
                            raum2.push(
                                <Tooltip key={key} disableHoverListener disableFocusListener title={longText}>
                                    <Paper className={classList.join(' ')} style={{backgroundColor: this.props.buchungen[key].farbe,height: height,top: top}} >
                                        {text}
                                        {this.props.user && this.props.buchungen[key].user === this.props.user.uid ? <Icon className="szbz-delete-appointment" onClick={()=> this.handleDialogOpen(key)}>delete</Icon> : ''}
                                    </Paper>
                                </Tooltip>
                            );
                        break;

                        case 21:
                            raum21.push(
                                <Tooltip key={key} disableHoverListener disableFocusListener title={longText}>
                                    <Paper className={classList.join(' ')} style={{backgroundColor: this.props.buchungen[key].farbe,height: height,top: top}} >
                                        {text}
                                        {this.props.user && this.props.buchungen[key].user === this.props.user.uid ? <Icon className="szbz-delete-appointment" onClick={()=> this.handleDialogOpen(key)}>delete</Icon> : ''}
                                    </Paper>
                                </Tooltip>
                            );
                        break;

                        case 3:
                            raum3.push(
                                <Tooltip key={key} disableHoverListener disableFocusListener title={longText}>
                                    <Paper className={classList.join(' ')} style={{backgroundColor: this.props.buchungen[key].farbe,height: height,top: top}} >
                                        {text}
                                        {this.props.user && this.props.buchungen[key].user === this.props.user.uid ? <Icon className="szbz-delete-appointment" onClick={()=> this.handleDialogOpen(key)}>delete</Icon> : ''}
                                    </Paper>
                                </Tooltip>
                            );
                        break;
                    
                        default:
                        break;
                    }
                }
            }

            rows.push(this.createData(i+':00 Uhr', raum1, raum2, raum21, raum3));

        }

        return (
            <>
                <Paper>
                    <Table stickyHeader>
                        <TableHead>
                        <TableRow>
                            <TableCell style={{width: '150px'}}>Uhrzeit</TableCell>
                            <TableCell align="center">1. Etage</TableCell>
                            <TableCell align="center">2. Etage groß</TableCell>
                            <TableCell align="center">2. Etage klein</TableCell>
                            <TableCell align="center">3. Etage</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.uhrzeit}>
                            <TableCell component="th" scope="row">
                                {row.uhrzeit}
                            </TableCell>
                            <TableCell className={this.tableCellClass}>{row.raum1}</TableCell>
                            <TableCell className={this.tableCellClass}>{row.raum2}</TableCell>
                            <TableCell className={this.tableCellClass}>{row.raum21}</TableCell>
                            <TableCell className={this.tableCellClass}>{row.raum3}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </Paper>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">"{this.state.deleteTitle}" löschen?</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sind Sie sicher, das Sie "<b>{this.state.deleteTitle}</b>" löschen wollen? 
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>  
                    <Button onClick={() => this.handleDelete()} color="primary">
                        Ja, diesen Termin löschen
                    </Button>
                    <Button onClick={() => this.handleBulkDelete()} color="primary">
                        Ja, alle Termine mit diesem Namen löschen
                    </Button>
                    <Button onClick={this.handleDialogClose} color="secondary" autoFocus>
                        Abbrechen
                    </Button>
                    </DialogActions>
                </Dialog>
            </>

        );

    }
  
}