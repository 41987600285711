import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { firebaseHandle } from '../user_accounts/Authentification';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



export default function CreateEditInfo(props) {

    const [editId, setEditId] = useState('');
    const [severity, setSeverity] = useState('');
    const [icon, setIcon] = useState('');
    const [text, setText] = useState('');
    const [linkText, setLinkText] = useState('');
    const [linkUrl, setLinkUrl] = useState('');
    const [external, setExternal] = useState(false);
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [showIconSelect, setShowIconSelect] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setEditId(props.editInfo.id);
        setSeverity(props.editInfo.severity);
        setIcon(props.editInfo.icon);
        setText(props.editInfo.text);
        if(props.editInfo.linkText) setLinkText(props.editInfo.linkText);
        if(props.editInfo.linkUrl) setLinkUrl(props.editInfo.linkUrl);
        if(props.editInfo.external) setExternal(props.editInfo.external);
        if(props.editInfo.file) setFileName(props.editInfo.file);
        setProgress(0);
    }, [props.editInfo])

    const handleSave = () => {

        if(editId === ''){
            firebaseHandle.db.collection("InfoBox").add({severity: severity, icon: icon, text: text, linkText: linkText, linkUrl: linkUrl, external: external, file: fileName, timestamp: new Date()});
        }
        else{
            firebaseHandle.db.doc("InfoBox/"+editId).update({severity: severity, icon: icon, text: text, linkText: linkText, external: external, linkUrl: linkUrl, file: fileName,});
        }
        handleClose();
    }


    const handleClose = () => {
        props.close();
        setEditId('');
        setSeverity('');
        setIcon('');
        setText('');
        setLinkText('');
        setLinkUrl('');
        setFile('');
        setFileName('');
        setExternal(false);
        setProgress(0);
        setShowIconSelect(false);
    }


    const uploadFile = (file) => {
        const tmpFileName = 'userUploads/' + new Date().getTime() + '_' + file.name;
        setFileName(tmpFileName);
        const uploadTask = firebaseHandle.storageRef.child(tmpFileName).put(file);
        uploadTask.on('state_changed', function(snapshot){
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress < 0.1 ? 0.1 : progress);
        }, function(error) {
            console.log(error);
            setProgress(0);
        }, function() {
            // Handle successful uploads on complete
            setProgress(0);
        });

    }

    const linkUrlField = () => {

        if(fileName === '')
        {
            return (
                <>
                    <TextField
                        label="Link Adresse"
                        variant="outlined"
                        value={linkUrl}
                        onChange={(evt)=>{setLinkUrl(evt.target.value)}}
                        style={{margin: '10px 20px'}}
                    />
                    <FormControlLabel
                        control={
                        <Switch
                            checked={external}
                            onChange={(evt)=>{setExternal(evt.target.checked)}}
                            color="secondary"
                        />
                        }
                        label="Externer Link"
                        style={{margin: '0 20px 10px 20px'}}
                    />
                </>
            );
        }
        else
        {
            return null;
        }

    }

    const uploadButton = (progress) => {

        if(progress === 0 && linkUrl==='')
        {
            return (
                <div style={{margin: '10px 20px'}}>
                    
                    <input
                        accept="application/pdf"
                        id="contained-button-file"
                        type="file"
                        onChange={(e)=> { 
                            setFile(e.target.files[0]);
                            uploadFile(e.target.files[0]);
                       }}
                       style={{display: 'none'}}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="secondary" component="div" style={{marginRight: '15px'}}>
                            Upload 
                        </Button>
                        {file.name}
                    </label>
                </div>
            );
        }
        else if(progress === 0)
        {
            return null;
        }
        else
        {
            return <LinearProgress variant="determinate" value={progress} style={{margin: '10px 20px'}}/>
        }

    }

    return (
        <Dialog onClose={handleClose} open={props.show}>
            <DialogTitle>Info erstellen</DialogTitle>
                <FormControl style={{margin: '10px 20px', minWidth: 315}}>
                    <InputLabel id="severityLabel">Farbe</InputLabel>
                    <Select
                        labelId="severityLabel"
                        value={severity}
                        onChange={(evt)=>{setSeverity(evt.target.value)}}
                    >
                        <MenuItem value='error'><span style={{color: '#f44336'}}>Rot</span></MenuItem>
                        <MenuItem value='warning'><span style={{color: '#ff9800'}}>Gelb</span></MenuItem>
                        <MenuItem value='info'><span style={{color: '#2196f3'}}>Blau</span></MenuItem>
                        <MenuItem value='success'><span style={{color: '#4caf50'}}>Grün</span></MenuItem>
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={icon}
                    onClick={()=>{setShowIconSelect(!showIconSelect)}}
                    style={{margin: '10px 20px'}}
                >
                    Icon
                </Button>

                { showIconSelect ? <Picker title='Icon Auswählen' onSelect={(icon) => {setIcon(icon.native); setShowIconSelect(false);}} /> : ''}

                <TextField
                    label="Text"
                    multiline
                    rows="5"
                    variant="outlined"
                    value={text}
                    onChange={(evt)=>{setText(evt.target.value)}}
                    style={{margin: '10px 20px'}}
                />

                <TextField
                    label="Link Titel"
                    variant="outlined"
                    value={linkText}
                    onChange={(evt)=>{setLinkText(evt.target.value)}}
                    style={{margin: '10px 20px'}}
                />

                {linkUrlField()}

                {uploadButton(progress)}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{float: 'right'}}
                >
                    { editId === '' ? 'Erstellen' : 'Ändern' }
                </Button>
        </Dialog>
    )
}