import React, {useState, useEffect} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import moment from "moment";
import "moment/locale/de";
import {getProfileImage} from '../user_accounts/Authentification';


moment.locale("de");

export default function KontakteDialog(props) {

    const [representation, setRepresentation] = useState('');

    useEffect(() => {
        if (props.contact.vacation && props.contact.vacation[2] && props.contact.vacation[2] !== null)
        {
            props.contact.vacation[2].get().then(element => {
                const representationUser = element.data();
                getProfileImage(representationUser.profileImage).then((url) => {
                    representationUser.imageUrl = url;
                    setRepresentation(<><br/>Vertretung: <Link href='#' onClick={(e)=>{e.preventDefault(); props.setActiveContact(representationUser)}} >{representationUser.firstName} {representationUser.lastName}</Link></>)
                })
            })
        }
    }, [props.contact])

    let vacation = '';
    let from = 0;
    let until = Number.MAX_VALUE;
    const now = new Date().getTime() / 1000;


    if(props.contact.vacation && props.contact.vacation[0] && props.contact.vacation[1])
    {
        if (props.contact.vacation[0] !== null)
        {
            from = props.contact.vacation[0].seconds;
        }
        if (props.contact.vacation[1] !== null)
        {
            until = props.contact.vacation[1].seconds;
        }

        if(from < now && until > now)
        {
            vacation = <div style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}><span style={{fontSize: '2rem', marginRight: '10px'}}>🌴</span> <p>{props.contact.firstName + ' ' + props.contact.lastName} ist noch bis einschließlich {moment.unix(props.contact.vacation[1].seconds).format('D. MMMM')} im Urlaub. {representation}</p></div>;
        }
    }

    return (
        <Dialog onClose={props.close} open={props.open} className="ticketDialog">
            <IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={props.close}>
                <CloseIcon />
            </IconButton>
            <DialogTitle style={{width: 'calc( 100% - 40px )'}}>
                {props.contact.firstName + ' ' + props.contact.lastName}
            </DialogTitle>
            <div style={{padding: '16px 24px'}}>
                <div style={{textAlign: 'center'}}>
                <img src={props.contact.imageUrl} alt='' style={{width: 500, maxWidth: '100%', height: 'auto'}} />
                </div>
                {vacation}
                <p>
                    <strong>Firma:</strong> {props.contact.firm}
                </p>
                <p>
                    <strong>E-Mail:</strong> <Link href={'mailto:' + props.contact.email} >{props.contact.email}</Link>
                </p>
                <p>
                    <strong>Tätigkeit:</strong> {props.contact.job}
                </p>
                <p>
                    <strong>Telefon:</strong> {props.contact.phone}
                </p>
                <p>
                    <strong>Mobiltelefon geschäftlich:</strong> {props.contact.mobilePhoneBusiness}
                </p>
                <p>
                    <strong>Mobiltelefon privat:</strong> {props.contact.mobilePhone}
                </p>
            </div>
            
        </Dialog>
    )
}
