import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {firebaseHandle} from '../user_accounts/Authentification';


export default function NewAppointmentInterval(props) {


    const [state, setState] = React.useReducer(
        (state, newState) => ({...state, ...newState}),
        {open: false, title: '', text: [], button: '', finished: false, datesOK: [], datesNotOK: []}
    )


    React.useEffect(() => {

 
        if(Object.entries(props.data).length !== 0)
        {   
            setState({open: true, completed: 0, title: "Bitte warten, Termine werden geprüft", text: [<LinearProgress key='progress'/>], button: '' });

            const datesOK = [];
            const datesNotOK = []
            const promises = []

            for (let i=0; i < props.data.dates.length; i++) {

                const date = props.data.dates[i];  
                promises.push(firebaseHandle.rtdb.ref('buchungen/'+ date.format('YYYY-MM-DD')).once('value'));              
            }

            
            Promise.all(promises).then(results => {

                let iterator = 0;
                results.forEach(function(res) {

                    let isFree = true;
                    let isFirst = true;
                    const appointmentFrom = props.data.appointment.von.clone();
                    const appointmentUntil = props.data.appointment.bis.clone();

                    appointmentFrom.year(props.data.dates[iterator].year()).month(props.data.dates[iterator].month()).date(props.data.dates[iterator].date());
                    appointmentUntil.year(props.data.dates[iterator].year()).month(props.data.dates[iterator].month()).date(props.data.dates[iterator].date());

                    for (const key in res.val()) {
            
                        if(res.val()[key].raum === props.data.appointment.raum)
                        {
                            const von = new Date(res.val()[key].von).getTime() / 1000;
                            const bis = new Date(res.val()[key].bis).getTime() / 1000;
                            
                            if( ((von < appointmentUntil.unix() && von > appointmentFrom.unix()) ||  (bis < appointmentUntil.unix() && bis > appointmentFrom.unix())) || ((appointmentFrom.unix() < bis && appointmentFrom.unix() > von) ||  (appointmentUntil.unix() < bis && appointmentUntil.unix() > von))  )
                            {
                                isFree = false;
                            }
                        }
                    }

                    if(isFree)
                    {

                        const newAppointment = {
                            von: appointmentFrom.format(),
                            bis: appointmentUntil.format(),
                            raum: props.data.appointment.raum,
                            titel: props.data.appointment.titel,
                            name: props.data.appointment.name,
                            farbe: props.data.appointment.farbe,
                            user: props.data.appointment.user
                        }


                        firebaseHandle.rtdb.ref('buchungen/'+ props.data.dates[iterator].format('YYYY-MM-DD')).push(newAppointment).then(() => {
                            if(isFirst)
                            {
                                props.finished();
                                isFirst = false;
                            }
                        })
                        
                        datesOK.push(props.data.dates[iterator].format('DD. MM. YYYY'));
                    }
                    else
                    {
                        datesNotOK.push(props.data.dates[iterator].format('DD. MM. YYYY'));
                    }

                    iterator++;

                    if(iterator === props.data.dates.length)
                    {
                        setState({finished: true, datesNotOK: datesNotOK, datesOK: datesOK});
                    }

                })
            })

        }
// eslint-disable-next-line
    }, [props.data])



    if(state.finished)
    {
        
        const text = [];

        if(state.datesNotOK.length > 0)
        {
            text.push(<h3 key='notOK' >Folgende Termine konnten nicht angelegt werden</h3>);
            state.datesNotOK.forEach(element => {
                text.push(<p key={element} style={{color: '#f44336', margin: '0'}}>{element}</p>);
            });
        }

        text.push(<h3 key='OK'>Folgende Termine wurden angelegt</h3>);
        state.datesOK.forEach(element => {
            text.push(<p key={element} style={{color: '#43a047', margin: '0'}}>{element}</p>);
        });

        setState({title: "Fertig", text: text, button: <Button onClick={() => setState({open: false})} color="primary" autoFocus>OK</Button>, finished: false});
    }


    return (

        <Dialog
            open={state.open}
        >
            <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
            <DialogContent>
                {state.text}
            </DialogContent>
            <DialogActions>
                {state.button}
            </DialogActions>
        </Dialog>
            
    )
}
