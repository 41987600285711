import React, {useState, useEffect} from 'react';
import MaterialTable from "material-table";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import { firebaseHandle, forceUserProfileUpdate } from '../user_accounts/Authentification';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function MyEasyTickets(props) {

    const [tickets, setTickets] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [activeTicket, setActiveTicket] = useState({});
    const [showForward, setShowForward] = useState(null);
    const [forwardUser, setForwardUser] = useState(null);
    const [forwardUserError, setForwardUserError] = useState(false);

    useEffect(() => {
        
        const tempTickets = [];
        const ticketPromises = [];

        props.easyTickets.forEach(element => {
            ticketPromises.push(element.get());
        });

        Promise.all(ticketPromises).then((tickets)=>{

            tickets.forEach(ticket => {

                const tmpTicket = ticket.data();
                tmpTicket.ref = ticket.ref; 
                tmpTicket.id = ticket.id;
                tmpTicket.shouldNotifyUser = false;

                if(tmpTicket.notify.filter(notify => notify.id === props.userProfileRef.id).length > 0){
                    tmpTicket.className = 'bold';
                    tmpTicket.shouldNotifyUser = true;
                }

                ticket.data().state[ticket.data().state.length -1].get().then((state)=>{
                    tmpTicket.state = state.data();

                    switch (tmpTicket.state.state) {
                        case 'abgelehnt':
                                tmpTicket.state.color = '#f44336';
                        break;

                        case 'genehmigt':
                                tmpTicket.state.color = '#4caf50';
                                tmpTicket.forward = <IconButton onClick={(e)=>{forwardTicket(e, tmpTicket)}}><ReplyIcon style={{transform: 'scaleX(-1)'}}/></IconButton>;
                        break;

                        case 'erledigt':
                            tmpTicket.state.color = '#4caf50';
                        break;

                        case 'weitergeleitet':
                                tmpTicket.state.color = '#ffc107';
                        break;
                    
                        default:
                            tmpTicket.state.color = 'inherit';
                        break;
                    }

                    tempTickets.push(tmpTicket);
                    
                    if(tempTickets.length === tickets.length)
                    {
                        setTickets(tempTickets);
                    }
                })
            });
        });

    }, [props.easyTickets])

    const handleNotificationClear = (ticket) => {
        if(ticket.shouldNotifyUser){
            const newNotifyArray = ticket.notify.filter(notify => notify.id !== props.userProfileRef.id);
            firebaseHandle.db.collection("/EasyTicket").doc(ticket.id).update({notify: newNotifyArray}).then(() => {
                forceUserProfileUpdate(ticket.notify.map(notify => notify.id));
            });
        }
    }

    const forwardTicket = (e, tmpTicket) => {
        e.stopPropagation();
        setActiveTicket(tmpTicket); 
        setShowDialog(true); 
        handleNotificationClear(tmpTicket);
        setShowForward(true);
    }


    const shouldShowForward = (showForward) => {

        if(showForward)
        {
            return (
                <>
                    <Autocomplete
                        options={props.receivers}
                        getOptionLabel={option => option.data().firstName + ' ' + option.data().lastName}
                        style={{ margin: '10px 20px' }}
                        value={forwardUser}
                        onChange={(event, newValue) => {
                            setForwardUser(newValue);
                        }}
                        renderInput={params => <TextField {...params} error={forwardUserError} label="Weiterleiten an" variant="outlined" style={{ width: '100%' }} />}
                    />
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => {

                            let error = false;
                            setForwardUserError(false);
                            if(forwardUser === null){
                                setForwardUserError(true);
                                error = true;
                            }

                            if(!error){
                                firebaseHandle.db.collection('EasyTicketStates').add({
                                    crdate: new Date(),
                                    state: 'weitergeleitet',
                                    user: props.userProfileRef
                                }).then((state) => {
                                    firebaseHandle.db.collection('EasyTicket').doc(activeTicket.id).update({
                                        notify: firebaseHandle.fieldValue.arrayUnion(forwardUser.ref),
                                        state: firebaseHandle.fieldValue.arrayUnion(state),
                                        reviser: forwardUser.data().firstName + ' ' + forwardUser.data().lastName
                                    });

                                    forwardUser.ref.update({
                                        easyTicketInquiries: firebaseHandle.fieldValue.arrayUnion(activeTicket.ref)
                                    });
                                });

                                setShowDialog(false);
                                setShowForward(false); 
                                setForwardUser(null); 
                                setForwardUserError(false);
                                forceUserProfileUpdate([props.userProfileRef.id]);
                        }

                    }} >Weiterleiten</Button>
                </>
            );
        }
    }


    const noTickets = props.easyTickets.length > 0 ? null : <h2 style={{marginLeft: 20}}>Keine Tickets vorhanden</h2>

    return (
        <>

            <MaterialTable
                columns={[
                    { 
                        title: "Betreff", 
                        field: "subject",
                        render: rowData => rowData.shouldNotifyUser ? <><span className='redDot' /> {rowData.subject}</> : rowData.subject
                    },
                    { 
                        title: 'Erstellt am', 
                        field: 'crdate',
                        render: rowData => new Date(rowData.crdate.seconds * 1000).toLocaleDateString()
                    },
                    { 
                        title: 'Geändert am', 
                        field: 'state',
                        render: rowData => new Date(rowData.state.crdate.seconds * 1000).toLocaleDateString()
                    },
                    { 
                        title: 'Status', 
                        field: 'state',
                        render: rowData => <span style={{color: rowData.state.color}}>{rowData.state.state}</span>
                    },
                    {title: 'Weiterleiten', field: 'forward'},
                ]}
                data={tickets}
                title="Meine Easy-Tickets"
                onRowClick={(event, rowData) => {
                    setActiveTicket(rowData); 
                    setShowDialog(true);
                    handleNotificationClear(rowData)
                }}
                options={{
                    paging: true,
                    tableLayout: 'fixed'
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Keine Tickets vorhanden',
                    },
                    toolbar: {
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchen',
                        showColumnsTitle: 'Zeige Spalten',
                        showColumnsAriaLabel: 'Zeige Spalten'
                    },
                    pagination: {
                        labelRowsPerPage: 'Zeilen pro Seite:',
                        labelRowsSelect: 'Zeilen',
                        labelDisplayedRows: '{from}-{to} von {count}',
                        firstTooltip: 'Erste Seite',
                        firstAriaLabel: 'Erste Seite',
                        previousAriaLabel: 'Vorherige Seite',
                        previousTooltip: 'Vorherige Seite',
                        nextTooltip: 'Nächste Seite',
                        nextAriaLabel: 'Nächste Seite',
                        lastTooltip: 'Letzte Seite',
                        lastAriaLabel:'Letzte Seite'
                    }
                }}
            />


            <Dialog onClose={()=>{setShowDialog(false); setShowForward(false); setForwardUser(null); setForwardUserError(false)}} open={showDialog} className="ticketDialog" >
                <IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>{setShowDialog(false); setShowForward(false); setForwardUser(null); setForwardUserError(false) }}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle style={{width: 'calc( 100% - 40px )'}}>
                    {activeTicket.subject}
                </DialogTitle>
                <TextField
                    label="Was wird gebraucht"
                    disabled 
                    multiline
                    rows="3"
                    variant="outlined"
                    value={activeTicket.what}
                    style={{margin: '10px 20px'}}
                />
                <TextField
                    label="Warum"
                    disabled 
                    multiline
                    rows="3"
                    variant="outlined"
                    value={activeTicket.why}
                    style={{margin: '10px 20px'}}
                />
                <TextField label="In Bearbeitung von" disabled variant="outlined" value={activeTicket.reviser} style={{margin: '10px 20px'}} />
                <TextField
                    label="Kommentar"
                    disabled 
                    multiline
                    rows="3"
                    variant="outlined"
                    value={activeTicket.comment}
                    style={{margin: '10px 20px'}}
                />
                <TextField label="Erstelldatum" disabled variant="outlined" value={activeTicket.crdate ? new Date(activeTicket.crdate.seconds * 1000).toLocaleString() : ''} style={{margin: '10px 20px'}} />
                <TextField label="Geändert am" disabled variant="outlined" value={activeTicket.state ? new Date(activeTicket.state.crdate.seconds * 1000).toLocaleString() : ''} style={{margin: '10px 20px'}} />
                <TextField label="Status" disabled variant="outlined" value={activeTicket.state ? activeTicket.state.state : ''} style={{margin: '10px 20px'}} />
                {shouldShowForward(showForward)}
            </Dialog>
        </>
    )
}
