import React, {useEffect, useState} from 'react';
import {firebaseHandle} from '../user_accounts/Authentification';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';

export default function InfoBoxes() {

    const [infoBoxes, setInfoBoxes] = useState([]);

    useEffect(() => {

        firebaseHandle.db.collection("InfoBox").orderBy('timestamp', 'desc').onSnapshot(function(querySnapshot) {
            const boxes = [];
            querySnapshot.forEach(element => {
                const data = element.data();
                const date = new Date(data.timestamp.seconds * 1000);
                const dateString = date.getDate() + '.' + (date.getMonth() + 1) +'. '+ date.getHours() +':'+ date.getMinutes() + ' Uhr';
                boxes.push(
                    <Alert variant="outlined" severity={data.severity} icon={data.icon} key={element.id} style={{position: 'relative', paddingBottom: 25}}>
                        {data.text}
                        <div style={{position: 'absolute', right: 5, bottom: 5, opacity: '50%'}}>{dateString}</div>
                        {linkButton(data.linkText, data.linkUrl, data.file, data.external)}
                    </Alert>);
            });
            setInfoBoxes(boxes);
        });

    }, []);


    const linkButton = (text, link, file, external) => {
        if(text && link){
            if(external){
                return <Button size="small" href={link} target="_blank" style={{position: 'absolute', left: 16, bottom: 0, opacity: '75%'}}>{text}</Button>
            }
            else{
                return <Button size="small" component={RouterLink} to={link} style={{position: 'absolute', left: 16, bottom: 0, opacity: '75%'}}>{text}</Button>
            }
            
        }
        else if(text && file){
            return <Button size="small" onClick={() => {
                firebaseHandle.storageRef.child(file).getDownloadURL().then((url)=>{
                    window.open(url, '_blank');
                });
            }} style={{position: 'absolute', left: 16, bottom: 0, opacity: '75%'}}>{text}</Button>
        }
    }


    return (
        <>
            {infoBoxes}
        </>
    )
}
