import React, {useState, useEffect} from 'react';
import Badge from '@material-ui/core/Badge';
import { firebaseHandle } from '../user_accounts/Authentification';

export default function DashboardBoxesBadge(props) {

    const [badgeContent, setBadgeContent] = useState(0);


    useEffect(() => {

        if(props.id === "OgvaTPqiqRlg9crlSfdl"){
            firebaseHandle.db.collection("EasyTicket").where("notify", "array-contains", props.userProfileRef).onSnapshot(function(notification) {
                setBadgeContent(notification.docs.length);
            });
        }
    }, [props.id, props.userProfileRef])
    


    return (
        <Badge color="error" badgeContent={badgeContent} />
    )
}
