import React, {useState, useEffect} from 'react';
import MaterialTable from "material-table";
import Avatar from '@material-ui/core/Avatar';
import {getUsers, getExtraContacts, getProfileImage} from '../user_accounts/Authentification';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import KontakteDialog from './KontakteDialog';
import CreateExtraContact from './CreateExtraContact';
import Fab from '@material-ui/core/Fab';
import {Add} from '@material-ui/icons';

export default function Kontakte(props) {


    const [users, setUsers] = useState([]);
    const [activeContact, setActiveContact] = useState({});
    const [openDetail, setOpenDetail] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const [lastWindowWidth, setLastWindowWidth] = useState(-1)
    const [editor, setEditor] = useState(false);
    const [showCreateExtraContact, setShowCreateExtraContact] = useState(false);
    const [tableColumns, setTableColumns] = useState([
        { 
            title: '', 
            field: 'imageUrl',
            cellStyle: {
                textAlign: 'right'
            },
            //render: rowData => <Avatar style={{display: 'inline-flex'}}>{rowData.lastName.charAt(0)}</Avatar> 
            render: rowData => <Avatar src={rowData.imageUrl} /> 
        },
        { title: "Name", field: "lastName" },
        { title: "Vorname", field: "firstName" },
        { title: "Firma/Abteilung", field: "firm" },
        { 
            title: 'E-Mail', 
            field: 'email',
            render: rowData => <Link href={"mailto:" + rowData.email} >{rowData.email}</Link> 
        },
        { title: "Tatigkeit", field: "job" },
        { title: "Telefon", field: "phone" },
        { title: "Mobiltelefon geschäftlich", field: "mobilePhoneBusiness" }
    ]);


    useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener("resize", debounce(()=>{setWindowWidth(window.innerWidth)}, 300));
        return () => {
            window.addEventListener("resize", debounce(()=>{setWindowWidth(window.innerWidth)}, 300));
        };
    }, [])

    useEffect(() => {


        setEditor(props.userProfile.roles.includes('contact-admin'));

        const contactPromises = [getUsers(), getExtraContacts()];

        Promise.all(contactPromises).then((usersDocs)=>{

            const allUserDocs = usersDocs[0].docs.concat(usersDocs[1].docs);

            const tmpUsers = [];
            const promises = [];
            allUserDocs.forEach(user => {
                tmpUsers.push({...user.data(), imageUrl: ''});
                promises.push(getProfileImage(user.data().profileImage));
            });

            Promise.all(promises).then((url)=>{
                for (let i = 0; i < tmpUsers.length; i++) {
                    tmpUsers[i].imageUrl = url[i];
                }
                setUsers(tmpUsers);
            });
        });

    }, [])


    function debounce(fn, delay) {
        var timer = null;
        return function () {
          var context = this, args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn.apply(context, args);
          }, delay);
        };
    }


    const handleCloseCreateExtraContact = () => {
        setShowCreateExtraContact(false);
    }

    const responsiveTable = (windowWidth) => {

        if(windowWidth !== lastWindowWidth)
        {
            setLastWindowWidth(windowWidth)


            setTableColumns([
                { 
                    title: '', 
                    field: 'imageUrl',
                    cellStyle: {
                        textAlign: 'left'
                    },
                    hidden: windowWidth < 700 ? true : false,
                    //render: rowData => <Avatar style={{display: 'inline-flex'}}>{rowData.lastName.charAt(0)}</Avatar> 
                    render: rowData => {
            
                        let vacation = '';
                        let from = 0;
                        let until = Number.MAX_VALUE;
                        const now = new Date().getTime()/1000;
        
                        if(rowData.vacation && rowData.vacation[0] && rowData.vacation[1])
                        {
                            if (rowData.vacation[0] !== null)
                            {
                                from = rowData.vacation[0].seconds;
                            }
                            if (rowData.vacation[1] !== null)
                            {
                                until = rowData.vacation[1].seconds;
                            }
        

                            if(from < now && until > now)
                            {
                                vacation = <span style={{fontSize: '1.5rem', verticalAlign: 'text-bottom', marginLeft: '-1rem', position: 'relative'}}>🌴</span>;
                            }
                        }
                        
                        return <><Avatar src={rowData.imageUrl} style={{display: 'inline-flex'}} /> {vacation}</>
                    }
                },
                { title: "Name", field: "lastName" },
                { title: "Vorname", field: "firstName" },
                { title: "Firma/Abteilung", field: "firm", hidden: windowWidth < 700 ? true : false },
                { 
                    title: 'E-Mail', 
                    field: 'email',
                    hidden: windowWidth < 1100 ? true : false,
                    render: rowData => <Link href={"mailto:" + rowData.email} >{rowData.email}</Link> 
                },
                { title: "Tatigkeit", field: "job", hidden: windowWidth < 1500 ? true : false },
                { title: "Telefon", field: "phone" },
                { title: "Mobiltelefon geschäftlich", field: "mobilePhoneBusiness", hidden: windowWidth < 1500 ? true : false }
            ]);
        }
       
    }


    const table = () => {

        if(users.length > 0)
        {
            return (
                <MaterialTable
                    columns={tableColumns}
                    data={users}
                    title="Kontakte"
                    options={{
                        paging: false,
                        tableLayout: 'fixed'
                    }}
                    onRowClick={(event, rowData) => {
                        setActiveContact(rowData);
                        setOpenDetail(true);
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Es wurden keine Kontakte gefunden',
                        },
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            showColumnsTitle: 'Zeige Spalten',
                            showColumnsAriaLabel: 'Zeige Spalten'
                        },
                        pagination: {
                            labelRowsPerPage: 'Zeilen pro Seite:',
                            labelRowsSelect: 'Zeilen',
                            labelDisplayedRows: '{from}-{to} von {count}',
                            firstTooltip: 'Erste Seite',
                            firstAriaLabel: 'Erste Seite',
                            previousAriaLabel: 'Vorherige Seite',
                            previousTooltip: 'Vorherige Seite',
                            nextTooltip: 'Nächste Seite',
                            nextAriaLabel: 'Nächste Seite',
                            lastTooltip: 'Letzte Seite',
                            lastAriaLabel:'Letzte Seite'
                        }
                    }}
                />
            )
        }
        else
        {
            return <CircularProgress style={{position: 'fixed', top: '48%', left: '48%'}}/>
        }

    }


    return (
        <>
            {responsiveTable(windowWidth)}
            {table()}
            <KontakteDialog open={openDetail} close={()=>{setOpenDetail(false)}} contact={activeContact} setActiveContact={setActiveContact}/>

            {editor ? <Fab color="secondary" style={{position: 'fixed', bottom: 20, right: 20}} onClick={() => {setShowCreateExtraContact(true)}}><Add /></Fab> : null }
            <CreateExtraContact show={showCreateExtraContact} close={handleCloseCreateExtraContact} />
        </>
    )
}
