import Firebase from '../firebase/Firebase';

const firebase = new Firebase();


export const firebaseHandle = firebase;

export const loginUser = (email, password) => {
    return firebase.auth.signInWithEmailAndPassword(email, password);
};

export const getUser = () =>
{
    return firebase.auth.currentUser;
}

export const getUsers = () =>
{
    return firebase.db.collection("Users").where('deleted', '==', false).get();
}

export const getExtraContacts = () =>
{
    return firebase.db.collection("ExtraContacts").get();
}

export const getUserProfile = () => 
{
    return firebase.db.collection("Users").doc(firebase.auth.currentUser.uid).get();
}

export const updateUserProfile = (profileFields) =>
{
    return firebase.db.collection('Users/').doc(firebase.auth.currentUser.uid).update(profileFields).catch((error)=>{
        console.log(error);
        alert('Speichern fehlgeschlagen!!!');
    });
    
}


export const getProfileImage = (image) => 
{
    return firebase.storageRef.child(image).getDownloadURL();
}

export const uploadProfileImage = (imageBlob) => 
{
    const path = 'images/users/' + imageBlob.name;
    return new Promise((resolve, reject) => {
        firebase.storageRef.child(path).put(imageBlob).then(function(snapshot) {
            updateUserProfile({profileImage: path}).then(()=>{
                resolve(path);
            }).catch(function(error) {
                console.log(error);
                reject();
            });
        }).catch(function(error) {
            console.log(error);
            reject();
        });
    });
       
}

export const forceUserProfileUpdate = (userIds) => {
    userIds.forEach(id => {
        firebase.db.collection("Users").doc(id).update({
            forceUpdate: firebase.fieldValue.increment(1)
        });
    });
}

export const getDashboardBoxes = () => {
    return firebase.db.collection("DashboardBox").get();
}

export const resetPassword = (email) => {
    firebase.auth.sendPasswordResetEmail(email);
}


export const isValidUser = () => {
    if(firebase.auth.currentUser !== null && firebase.auth.currentUser.emailVerified)
    {
        return true;
    }
    else
    {
        return false;
    }
};

export const signUpUser = user => {
    firebase.auth.createUserWithEmailAndPassword(user.email, user.password)
      .then(authUser => {
		firebase.db.collection("Users").doc(authUser.user.uid).set({
			firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            mobilePhone: user.mobilePhone,
            mobilePhoneBusiness: user.mobilePhoneBusiness,
            birthday: user.birthday,
			job: user.job,
            firm: user.firm,
            email: user.email,
            userDashboardBoxes: [],
            roles: user.roles != null ? user.roles : [],
            profileImage: 'images/users/default.jpg',
            darkMode: true,
            primaryColor: 'blue',
            secondaryColor: 'purple',
            easyTicketInquiries: [],
            easyTickets: [],
            forceUpdate: 0,
            deleted: false
		}).then(() => {

            firebase.auth.languageCode = 'de';
		
            const firebaseUser = firebase.auth.currentUser;

            firebaseUser.sendEmailVerification().then(function() {
                window.location.href = '/';
            }).catch(function(error) {
            console.log(error)
            });

        });

      })
      .catch(error => {
		if(error.code === 'auth/email-already-in-use')
		{
			alert("Diese E-Mail Adresse wurde bereits registriert.");
		}
	  });
};