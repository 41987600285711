import React, {useState, useEffect} from 'react';
import MaterialTable from "material-table";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { firebaseHandle, forceUserProfileUpdate } from '../user_accounts/Authentification';


export default function Inquiries(props) {

    const [tickets, setTickets] = useState([]);

    const [showDialog, setShowDialog] = useState(false);
    const [activeTicket, setActiveTicket] = useState({});
    const [comment, setComment] = useState(' ');

    useEffect(() => {
        
        const tempTickets = [];
        const ticketPromises = [];

        props.easyTicketInquiries.forEach(element => {
            ticketPromises.push(element.get());
        });

        Promise.all(ticketPromises).then((tickets)=>{

            tickets.forEach(ticket => {

                const tmpTicket = ticket.data();     
                tmpTicket.id = ticket.id;

                tmpTicket.shouldNotifyUser = false;
                if(tmpTicket.notify.filter(notify => notify.id === props.userProfileRef.id).length > 0){
                    tmpTicket.shouldNotifyUser = true;
                }

                ticket.data().creator.get().then((creator)=>{
                    tmpTicket.creatorName = creator.data().firstName + ' ' + creator.data().lastName;

                    ticket.data().state[ticket.data().state.length -1].get().then((state)=>{
                        tmpTicket.state = state.data();

                        switch (tmpTicket.state.state) {
                            case 'abgelehnt':
                                    tmpTicket.state.color = '#f44336';
                            break;

                            case 'genehmigt':
                            case 'erledigt':
                                    tmpTicket.state.color = '#4caf50';
                            break;

                            case 'weitergeleitet':
                                    tmpTicket.state.color = '#ffc107';
                            break;

                            default:
                                tmpTicket.state.color = 'inherit';
                            break;
                        }

                        tempTickets.push(tmpTicket);
                        
                        if(tempTickets.length === tickets.length)
                        {
                            setTickets(tempTickets);
                        }
                    })
                })

            });
        });

    }, [props.easyTicketInquiries])

    const showButtons = () => {

        if(activeTicket.state && activeTicket.state.state === 'offen'){
            return (
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px 20px'}}>
                    <Button variant="contained" color="red" style={{backgroundColor: red[500], color: 'white'}} onClick={rejectTicket} >
                        Ablehnen
                    </Button> 
                    <Button variant="contained" style={{backgroundColor: green[500], color: 'white'}} onClick={approveTicket} >
                        Genehmigen
                    </Button>
                </div>
            )
        }
        else if(activeTicket.state && activeTicket.state.state === 'weitergeleitet'){
            return (
                <div>
                    <Button variant="contained" style={{backgroundColor: green[500], color: 'white', width: '100%'}} onClick={finishTicket} >
                        Erledigt
                    </Button>
                </div>
            )
        }
        else{
            return ''
        }

    }

    const approveTicket = () => {
        firebaseHandle.db.collection('EasyTicketStates').add({
            crdate: new Date(),
            state: 'genehmigt',
            user: props.userProfileRef
        }).then((state) => {
            firebaseHandle.db.collection('EasyTicket').doc(activeTicket.id).update({
                notify: [activeTicket.creator],
                state: firebaseHandle.fieldValue.arrayUnion(state),
                comment: comment
            });
            forceUserProfileUpdate([props.userProfileRef.id, activeTicket.creator.id]);
        });
        setShowDialog(false);
    }

    const rejectTicket = () => {
        firebaseHandle.db.collection('EasyTicketStates').add({
            crdate: new Date(),
            state: 'abgelehnt',
            user: props.userProfileRef
        }).then((state) => {
            firebaseHandle.db.collection('EasyTicket').doc(activeTicket.id).update({
                notify: [activeTicket.creator],
                state: firebaseHandle.fieldValue.arrayUnion(state),
                comment: comment
            });
            forceUserProfileUpdate([props.userProfileRef.id, activeTicket.creator.id]);
        });
        setShowDialog(false);
    }


    const finishTicket = () => {
        firebaseHandle.db.collection('EasyTicketStates').add({
            crdate: new Date(),
            state: 'erledigt',
            user: props.userProfileRef
        }).then((state) => {
            firebaseHandle.db.collection('EasyTicket').doc(activeTicket.id).update({
                notify: [activeTicket.creator],
                state: firebaseHandle.fieldValue.arrayUnion(state),
                comment: comment
            });
            forceUserProfileUpdate([props.userProfileRef.id, activeTicket.creator.id]);
        });
        setShowDialog(false);
    }

    return (
        <>
            <MaterialTable
                columns={[
                    { 
                        title: "Betreff", 
                        field: "subject",
                        render: rowData => rowData.shouldNotifyUser ? <><span className='redDot' /> {rowData.subject}</> : rowData.subject
                    },
                    { title: "Antragssteller", field: "creatorName" },
                    { 
                        title: 'Erstellt am', 
                        field: 'crdate',
                        render: rowData => new Date(rowData.crdate.seconds * 1000).toLocaleDateString()
                    },
                    { 
                        title: 'Geändert am', 
                        field: 'state',
                        render: rowData => new Date(rowData.state.crdate.seconds * 1000).toLocaleDateString()
                    },
                    { 
                        title: 'Status', 
                        field: 'state',
                        render: rowData => <span style={{color: rowData.state.color}}>{rowData.state.state}</span>
                    },
                ]}
                data={tickets}
                title="Easy-Ticket Anfragen"
                onRowClick={(event, rowData) => {
                    setActiveTicket(rowData); 
                    setComment(rowData.comment); 
                    setShowDialog(true)
                }}
                options={{
                    paging: true,
                    tableLayout: 'fixed'
                }}
                style={{marginBottom: 30}}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Keine Tickets vorhanden',
                    },
                    toolbar: {
                        searchTooltip: 'Suchen',
                        searchPlaceholder: 'Suchen',
                        showColumnsTitle: 'Zeige Spalten',
                        showColumnsAriaLabel: 'Zeige Spalten'
                    },
                    pagination: {
                        labelRowsPerPage: 'Zeilen pro Seite:',
                        labelRowsSelect: 'Zeilen',
                        labelDisplayedRows: '{from}-{to} von {count}',
                        firstTooltip: 'Erste Seite',
                        firstAriaLabel: 'Erste Seite',
                        previousAriaLabel: 'Vorherige Seite',
                        previousTooltip: 'Vorherige Seite',
                        nextTooltip: 'Nächste Seite',
                        nextAriaLabel: 'Nächste Seite',
                        lastTooltip: 'Letzte Seite',
                        lastAriaLabel:'Letzte Seite'
                    }
                }}
            />


            <Dialog onClose={()=>{setShowDialog(false)}} open={showDialog} className="ticketDialog">
                <IconButton style={{position: 'absolute', right: '10px', top: '10px'}} onClick={()=>{setShowDialog(false)}}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle style={{width: 'calc( 100% - 40px )'}}>
                    {activeTicket.subject}
                </DialogTitle>
                <TextField
                    label="Was wird gebraucht"
                    disabled 
                    multiline
                    rows="3"
                    variant="outlined"
                    value={activeTicket.what}
                    style={{margin: '10px 20px'}}
                />
                <TextField
                    label="Warum"
                    disabled 
                    multiline
                    rows="3"
                    variant="outlined"
                    value={activeTicket.why}
                    style={{margin: '10px 20px'}}
                />
                <TextField label="In Bearbeitung von" disabled variant="outlined" value={activeTicket.reviser} style={{margin: '10px 20px'}} />
                <TextField label="Erstellt am" disabled variant="outlined" value={activeTicket.crdate ? new Date(activeTicket.crdate.seconds * 1000).toLocaleString() : ''} style={{margin: '10px 20px'}} />
                <TextField label="Geändert am" disabled variant="outlined" value={activeTicket.state ? new Date(activeTicket.state.crdate.seconds * 1000).toLocaleString() : ''} style={{margin: '10px 20px'}} />
                <TextField label="Status" disabled variant="outlined" value={activeTicket.state ? activeTicket.state.state : ''} style={{margin: '10px 20px'}} />

                <TextField
                    label="Kommentar"
                    multiline
                    disabled = {activeTicket.state && (activeTicket.state.state === 'offen' || activeTicket.state.state === 'weitergeleitet') ? false : true}
                    autoFocus
                    rows="3"
                    variant="outlined"
                    value={comment}
                    onChange={(evt) => {setComment(evt.target.value)}}
                    style={{margin: '10px 20px'}}
                />

                {showButtons()}
            </Dialog>
        </>
    )
}
