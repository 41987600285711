import React, {useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import {firebaseHandle} from '../user_accounts/Authentification';
import {Edit, Delete, Add} from '@material-ui/icons';
import CreateEditInfo from './CreateEditInfo';

export default function InfoAdmin() {


    const [infoBoxes, setInfoBoxes] = useState([]);
    const [showCreateEditInfo, setShowCreateEditInfo] = useState(false);
    const [editInfo, setEditInfo] = useState({severity: '', icon:  '', text: '', id: ''});

    useEffect(() => {

        firebaseHandle.db.collection("InfoBox").orderBy('timestamp', 'desc').onSnapshot(function(querySnapshot) {
            const boxes = [];
            querySnapshot.forEach((element) => {
                const data = element.data();
                let color;
                switch (data.severity) {
                    case 'error':
                        color = <span style={{color: '#f44336'}}>Rot</span>;
                    break;
                    case 'warning':
                        color = <span style={{color: '#ff9800'}}>Gelb</span>;
                    break;
                    case 'info':
                        color = <span style={{color: '#2196f3'}}>Blau</span>;
                    break;
                    case 'success':
                        color = <span style={{color: '#4caf50'}}>Grün</span>;
                    break;
                    default:
                }
                boxes.push({color: color, icon:  data.icon, text: data.text, linkText: data.linkText, linkUrl: data.linkUrl, fileName: data.file, edit: <IconButton onClick={()=>{handleEdit({severity: data.severity, icon:  data.icon, text: data.text, id: element.id, linkText: data.linkText, linkUrl: data.linkUrl, external: data.external, fileName: data.file})}}><Edit /></IconButton>, delete: <IconButton onClick={()=>{handleDelete(element.id)}}><Delete /></IconButton>});
            });
            setInfoBoxes(boxes);
        });

    }, []);


    const handleEdit = (info) => {
        setEditInfo(info);
        setShowCreateEditInfo(true);
    }

    const handleDelete = (id) => {
        firebaseHandle.db.doc('/InfoBox/'+id).delete();
    }

    const handleCloseCreateEdit = () => {
        setEditInfo({severity: '', icon:  '', text: '', id: ''});
        setShowCreateEditInfo(false);
    }

    return (
        <>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>Farbe</TableCell>
                    <TableCell>Icon</TableCell>
                    <TableCell>Text</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {infoBoxes.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.color}</TableCell>
                        <TableCell>{row.icon}</TableCell>
                        <TableCell>{row.text}</TableCell>
                        <TableCell>{row.linkText}</TableCell>
                        <TableCell>{row.edit}</TableCell>
                        <TableCell>{row.delete}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

            <Fab color="secondary" style={{position: 'fixed', bottom: 20, right: 20}} onClick={() => {setShowCreateEditInfo(true)}}>
                <Add />
            </Fab>

            <CreateEditInfo show={showCreateEditInfo} close={handleCloseCreateEdit} editInfo={editInfo} />
        </>
    )
}
