import React, {useState, useEffect} from 'react';
import Alert from '@material-ui/lab/Alert';
import moment from "moment";
import "moment/locale/de";
import { firebaseHandle } from '../user_accounts/Authentification';

export default function Birthdays() {

    const [birthdays, setBirthdays] = useState('');

    useEffect(() => {
        
        const start = moment().hour(0).minute(0).second(0);
        const end = moment().hour(23).minute(59).second(59);

        firebaseHandle.db.collection("Users").orderBy("birthday").startAt(start.unix()).endAt(end.unix()).get().then((res)=>{

            const birthdayArr = res.docs;
            
            if(birthdayArr.length > 0)
            {
                let birthdaysJsxArray = ['Wir gratulieren heute '];

                for(let i=0; i < birthdayArr.length; i++)
                {
                    birthdaysJsxArray.push(<b key={birthdayArr[i].data().firstName +' '+ birthdayArr[i].data().lastName}>{birthdayArr[i].data().firstName +' '+ birthdayArr[i].data().lastName}</b>);

                    if(i + 1 === birthdayArr.length - 1)
                    {
                        birthdaysJsxArray.push(' und ');

                    } else if(i + 1 < birthdayArr.length)
                    {
                        birthdaysJsxArray.push(', ');
                    }
                }

                birthdaysJsxArray.push(' zum Geburtstag');

                setBirthdays(
                    <Alert icon={'🎂'} variant="filled" severity="info" key='birthday' >
                        <span>{birthdaysJsxArray}</span>
                    </Alert>
                );
            }
        });

    }, [])



    return (
        <>
            {birthdays}
        </>
    )
}
