import React, { useState, useEffect } from 'react'
import MaterialTable from "material-table";
import { firebaseHandle, loginUser } from '../user_accounts/Authentification';
import moment from "moment";
import "moment/locale/de";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
const axios = require('axios').default;



export default function PrivateDocuments(props) {

    const [entgeltabrechnungen, setEntgeltabrechnungen] = useState([]);
    const [stempelkarten, setStempelkarten] = useState([]);
    const [meldebescheinigungen, setMeldebescheinigungen] = useState([]);
    const [lohnsteuerbescheinigungen, setLohnsteuerbescheinigungen] = useState([]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [doubleLogin, setDoubleLogin] = useState(false);
    const [alert, setAlert] = useState('');


    useEffect(() => {

        if(!props.userProfile.personnelNumber)
        {
            return
        }

        const tmpEntgeltabrechnungen = [];
        const tmpStempelkarten = [];
        const tmpMeldebescheinigungen = [];
        const tmpLohnsteuerbescheinigungen = [];


        const tmpFiles = [];
        props.userProfile.personnelNumber.forEach(personnelNumber => {
            tmpFiles.push(firebaseHandle.storageRef.child("privateDocuments/" + personnelNumber).listAll());
        });

        Promise.all(tmpFiles).then((res) => {

            res.forEach(element => {
                element.items.forEach(file => {
                    if (file.location.path_.search('Entgeltabrechnung') !== -1) {
                        const regexPersonnelNumber = /(?<=privateDocuments\/)(.*)(?=\/)/;
                        const regexDate = /(?<=Entgeltabrechnung_)(.*)(?=_)/;

                        const personnelNumber = regexPersonnelNumber.exec(file.location.path_);
                        const dateYMDString = regexDate.exec(file.location.path_);

                        const date = moment(dateYMDString[0], "YYYYMMDD");

                        let korrektur = false;
                        if (file.location.path_.search('korrektur') !== -1) {
                            korrektur = true;
                        }

                        const pageNumber = file.location.path_.substring(file.location.path_.length-5, file.location.path_.length-4)

                        tmpEntgeltabrechnungen.push({ file: file.location.path_, date: korrektur ? date.format("MMMM YYYY") + " Korrektur" : date.format("MMMM YYYY"), ymdDate: dateYMDString[0], personnelNumber: personnelNumber[0], pageNumber: pageNumber});
                    }
                    else if (file.location.path_.search('Stempelkarte') !== -1) {
                        const regexPersonnelNumber = /(?<=privateDocuments\/)(.*)(?=\/)/;
                        const regexDate = /(?<=Stempelkarte_)(.*)(?=_)/;

                        const personnelNumber = regexPersonnelNumber.exec(file.location.path_);
                        const dateYMDString = regexDate.exec(file.location.path_);

                        const date = moment(dateYMDString[0], "YYYYMMDD");

                        let korrektur = false;
                        if (file.location.path_.search('korrektur') !== -1) {
                            korrektur = true;
                        }

                        tmpStempelkarten.push({ file: file.location.path_, date: korrektur ? date.format("MMMM YYYY") + " Korrektur" : date.format("MMMM YYYY"), ymdDate: dateYMDString[0], personnelNumber: personnelNumber[0] });
                    }
                    else if (file.location.path_.search('Meldebescheinigung') !== -1) {
                        const regexPersonnelNumber = /(?<=privateDocuments\/)(.*)(?=\/)/;
                        const personnelNumber = regexPersonnelNumber.exec(file.location.path_);

                        const regexDate = /(?<=Meldebescheinigung_)(.*)(?=_)/;
                        const date = regexDate.exec(file.location.path_);

                        let korrektur = false;
                        if (file.location.path_.search('korrektur') !== -1) {
                            korrektur = true;
                        }

                        tmpMeldebescheinigungen.push({ file: file.location.path_, date: korrektur ? date[0] + " Korrektur" : date[0], personnelNumber: personnelNumber[0] });
                    }
                    else if (file.location.path_.search('Lohnsteuerbescheinigung') !== -1) {
                        const regexPersonnelNumber = /(?<=privateDocuments\/)(.*)(?=\/)/;
                        const personnelNumber = regexPersonnelNumber.exec(file.location.path_);

                        const regexDate = /(?<=Lohnsteuerbescheinigung_)(.*)(?=_)/;
                        const date = regexDate.exec(file.location.path_);

                        console.log(date)

                        let korrektur = false;
                        if (file.location.path_.search('korrektur') !== -1) {
                            korrektur = true;
                        }

                        tmpLohnsteuerbescheinigungen.push({ file: file.location.path_, date: korrektur ? date[0] + " Korrektur" : date[0], personnelNumber: personnelNumber[0] });
                        console.log(tmpLohnsteuerbescheinigungen);
                    }
                });
            });

            setEntgeltabrechnungen(tmpEntgeltabrechnungen);
            setStempelkarten(tmpStempelkarten);
            setMeldebescheinigungen(tmpMeldebescheinigungen);
            setLohnsteuerbescheinigungen(tmpLohnsteuerbescheinigungen);
        });

    }, []);

    if(!props.userProfile.personnelNumber)
    {
        return <Redirect to='/' />
    }


    const submitLogin = (event) => {
        event.preventDefault();
        setAlert('');
        loginUser(email, password).then(data => {
            setDoubleLogin(true);
        }).catch(function(error) {
            setAlert(<Alert style={{marginBottom: '25px'}} severity="error">E-Mail oder Passwort falsch</Alert>)
        });
    }

    const sendProtocolMail = (documentName) => {
        
        axios.post('https://us-central1-intranet-roehm-medien.cloudfunctions.net/protocol ', {
            datetime: moment().format('LLLL'),
            document: documentName,
            email: props.userProfile.email,
            name: props.userProfile.firstName + " " + props.userProfile.lastName 
          }).catch(function (error) {
            console.log(error);
          });
    }
    
    if(doubleLogin)
    {
        return (
            <>
                <MaterialTable
                    columns={[
                        {
                            title: "Datum",
                            field: "date"
                        },
                        {
                            title: "Seite",
                            field: "pageNumber"
                        },
                        {
                            title: 'Mitarbeiternummer',
                            field: 'personnelNumber'
                        },
                        {
                            title: "YYYYMMDD Datum",
                            field: "ymdDate",
                            defaultSort: 'desc',
                            hidden: true
                        },
                    ]}
    
                    data={entgeltabrechnungen}
                    title="Meine Entgeltabrechnungen"
                    onRowClick={(event, rowData) => {
                        sendProtocolMail(rowData.file);
                        firebaseHandle.storageRef.child(rowData.file).getDownloadURL().then((url) => {
                            window.open(url);
                        }).catch((error) => {
                            console.log(error);
                        });
    
                    }}
                    options={{
                        paging: true,
                        tableLayout: 'fixed'
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Keine Entgeltabrechnungen gefunden',
                        },
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            showColumnsTitle: 'Zeige Spalten',
                            showColumnsAriaLabel: 'Zeige Spalten'
                        },
                        pagination: {
                            labelRowsPerPage: 'Zeilen pro Seite:',
                            labelRowsSelect: 'Zeilen',
                            labelDisplayedRows: '{from}-{to} von {count}',
                            firstTooltip: 'Erste Seite',
                            firstAriaLabel: 'Erste Seite',
                            previousAriaLabel: 'Vorherige Seite',
                            previousTooltip: 'Vorherige Seite',
                            nextTooltip: 'Nächste Seite',
                            nextAriaLabel: 'Nächste Seite',
                            lastTooltip: 'Letzte Seite',
                            lastAriaLabel: 'Letzte Seite'
                        }
                    }}
                />
    
                <div style={{height: "25px"}}></div>
    
                <MaterialTable
                    columns={[
                        {
                            title: "Datum",
                            field: "date"
                        },
                        {
                            title: 'Mitarbeiternummer',
                            field: 'personnelNumber'
                        },
                        {
                            title: "YYYYMMDD Datum",
                            field: "ymdDate",
                            defaultSort: 'desc',
                            hidden: true
                        },
                    ]}
    
                    data={stempelkarten}
                    title="Meine Stempelkarten"
                    onRowClick={(event, rowData) => {
                        sendProtocolMail(rowData.file);
                        firebaseHandle.storageRef.child(rowData.file).getDownloadURL().then((url) => {
                            window.open(url);
                        }).catch((error) => {
                            console.log(error);
                        });
    
                    }}
                    options={{
                        paging: true,
                        tableLayout: 'fixed'
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Keine Stempelkarten gefunden',
                        },
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            showColumnsTitle: 'Zeige Spalten',
                            showColumnsAriaLabel: 'Zeige Spalten'
                        },
                        pagination: {
                            labelRowsPerPage: 'Zeilen pro Seite:',
                            labelRowsSelect: 'Zeilen',
                            labelDisplayedRows: '{from}-{to} von {count}',
                            firstTooltip: 'Erste Seite',
                            firstAriaLabel: 'Erste Seite',
                            previousAriaLabel: 'Vorherige Seite',
                            previousTooltip: 'Vorherige Seite',
                            nextTooltip: 'Nächste Seite',
                            nextAriaLabel: 'Nächste Seite',
                            lastTooltip: 'Letzte Seite',
                            lastAriaLabel: 'Letzte Seite'
                        }
                    }}
                />
    
                <div style={{height: "25px"}}></div>
    
                <MaterialTable
                    columns={[
                        {
                            title: "Jahr",
                            field: "date",
                            defaultSort: 'desc'
                        },
                        {
                            title: 'Mitarbeiternummer',
                            field: 'personnelNumber'
                        }
                    ]}
    
                    data={meldebescheinigungen}
                    title="Meine Meldebescheinigungen"
                    onRowClick={(event, rowData) => {
                        sendProtocolMail(rowData.file);
                        firebaseHandle.storageRef.child(rowData.file).getDownloadURL().then((url) => {
                            window.open(url);
                        }).catch((error) => {
                            console.log(error);
                        });
    
                    }}
                    options={{
                        paging: true,
                        tableLayout: 'fixed'
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Keine Meldebescheinigungen gefunden',
                        },
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            showColumnsTitle: 'Zeige Spalten',
                            showColumnsAriaLabel: 'Zeige Spalten'
                        },
                        pagination: {
                            labelRowsPerPage: 'Zeilen pro Seite:',
                            labelRowsSelect: 'Zeilen',
                            labelDisplayedRows: '{from}-{to} von {count}',
                            firstTooltip: 'Erste Seite',
                            firstAriaLabel: 'Erste Seite',
                            previousAriaLabel: 'Vorherige Seite',
                            previousTooltip: 'Vorherige Seite',
                            nextTooltip: 'Nächste Seite',
                            nextAriaLabel: 'Nächste Seite',
                            lastTooltip: 'Letzte Seite',
                            lastAriaLabel: 'Letzte Seite'
                        }
                    }}
                />
    
                <div style={{height: "25px"}}></div>
    
                <MaterialTable
                    columns={[
                        {
                            title: "Jahr",
                            field: "date",
                            defaultSort: 'desc'
                        },
                        {
                            title: 'Mitarbeiternummer',
                            field: 'personnelNumber'
                        }
                    ]}
    
                    data={lohnsteuerbescheinigungen}
                    title="Meine Lohnsteuerbescheinigungen"
                    onRowClick={(event, rowData) => {
                        sendProtocolMail(rowData.file);
                        firebaseHandle.storageRef.child(rowData.file).getDownloadURL().then((url) => {
                            window.open(url);
                        }).catch((error) => {
                            console.log(error);
                        });
    
                    }}
                    options={{
                        paging: true,
                        tableLayout: 'fixed'
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: 'Keine Lohnsteuerbescheinigungen gefunden',
                        },
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            showColumnsTitle: 'Zeige Spalten',
                            showColumnsAriaLabel: 'Zeige Spalten'
                        },
                        pagination: {
                            labelRowsPerPage: 'Zeilen pro Seite:',
                            labelRowsSelect: 'Zeilen',
                            labelDisplayedRows: '{from}-{to} von {count}',
                            firstTooltip: 'Erste Seite',
                            firstAriaLabel: 'Erste Seite',
                            previousAriaLabel: 'Vorherige Seite',
                            previousTooltip: 'Vorherige Seite',
                            nextTooltip: 'Nächste Seite',
                            nextAriaLabel: 'Nächste Seite',
                            lastTooltip: 'Letzte Seite',
                            lastAriaLabel: 'Letzte Seite'
                        }
                    }}
                />
    
            </>
        )
    }
    else
    {
        return (
            <Grid container spacing={0} justify="center">
                <Grid item xs={12} sm={10} md={6} lg={3}>
                    <Paper style={{padding: '20px'}} elevation={15} >
                        <form id="loginForm" noValidate autoComplete="on" onSubmit={submitLogin}>
                            {alert}
                            <TextField label="E-Mail" variant="outlined" value={email} onChange={(event) => {setEmail(event.target.value)}} />
                            <TextField label="Passwort" variant="outlined" type="password" value={password} onChange={(event) => {setPassword(event.target.value)}} />
                            <Button variant="contained" color="primary" type="submit" style={{float: 'right'}}>
                                Anmelden
                            </Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>       
        )
    }

    
}
