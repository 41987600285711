import React, {useEffect, useState} from 'react';
import MyEasyTickets from './MyEasyTickets';
import Inquiries from './Inquiries';
import Grid from '@material-ui/core/Grid';
import { firebaseHandle } from '../user_accounts/Authentification';
import Fab from '@material-ui/core/Fab';
import Add from '@material-ui/icons/Add';
import CreateTicket from './CreateTicket';
import './EasyTicket.css';


export default function EasyTicket(props) {

    const [receivers, setReceivers] = useState([]);
    const [showCreateTicket, setShowCreateTicket] = useState(false);

    useEffect(() => {
        firebaseHandle.db.collection("Users").orderBy("firstName").get().then((res)=>{
            setReceivers(res.docs);
        });
    }, [])

    const easyTicketAnfragen = () => 
    {
        if(props.easyTicketInquiries.length > 0)
        {
            return (
                <Inquiries easyTicketInquiries={[...props.easyTicketInquiries].reverse()} user={props.user} userProfileRef={props.userProfileRef} />
            )
        }
    }

    return (
        <>
            <Grid container spacing={0} justify="center" alignItems="stretch">
                <Grid item xs={12} lg={10}>
                    {easyTicketAnfragen()}
                    <MyEasyTickets easyTickets={[...props.easyTickets].reverse()} userProfileRef={props.userProfileRef} receivers={receivers} forceUpdate={props.forceUpdate}/>
                </Grid>
            </Grid>

            <Fab color="secondary" style={{position: 'fixed', bottom: 20, right: 20}} onClick={() => {setShowCreateTicket(true)}}>
                <Add />
            </Fab>

            <CreateTicket show={showCreateTicket} receivers={receivers} user={props.user} userProfileRef={props.userProfileRef} close={() => {setShowCreateTicket(false)}} />
        </>
    )
}
