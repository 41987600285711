import React from 'react';
import './Dashboard.css';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Birthdays from './Birthdays';
import Alert from '@material-ui/lab/Alert';
import DashboardBoxes from './DashboardBoxes';
import InfoBoxes from './InfoBoxes';
import { Link as RouterLink } from 'react-router-dom';

export default function Dashboard(props) {


    return (
        <div style={{padding: '0 5vw'}}>
            
            <Grid container spacing={0} justify="center" alignItems="stretch">
                <Grid item xs={12}>
                    {props.userProfile.profileImage === 'images/users/default.jpg' ? (
                        <Alert variant="filled" severity="warning">
                            <span>Bitte laden Sie ein Profilbild hoch. <Button component={RouterLink} to='/einstellungen' >Jetzt hochladen</Button></span>
                        </Alert>
                    ) : ''}
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Paper className="info-box">
                        <Birthdays />
                        <InfoBoxes />
                    </Paper>
                </Grid>
                <Grid container item spacing={0} xs={12} sm={12} md={9} alignItems="flex-start" style={{height: 'max-content'}}>
                    <DashboardBoxes userProfile={props.userProfile} userProfileRef={props.userProfileRef} />
                </Grid>
            </Grid>
        </div>
    )
}
