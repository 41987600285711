import React, { useState, useCallback, useEffect } from 'react';
import { Edit } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './ProfileImage.css';
import { getProfileImage, uploadProfileImage } from '../user_accounts/Authentification';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export default function ProfileImage(props) {

    const [profileImage, setProfileImage] = useState('');
    const [upImg, setUpImg] = useState();
    const [imgRef, setImgRef] = useState(null);
    const [crop, setCrop] = useState({ 
        aspect: 1 / 1,
        unit: '%',
        width: 100,
        height: 100,
    });
    const [profileImageDisplay, setProfileImageDisplay] = useState('block');
    const [showControls, setShowControls] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);



    useEffect(() => {

        if(props.userProfile.profileImage)
        {
            getProfileImage(props.userProfile.profileImage).then((url)=>{
                setProfileImage(url);
            }).catch((error)=>{
                console.log(error);
            })
        }

    }, [props.userProfile])

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {setUpImg(reader.result); setShowControls(true); setShowSpinner(false);});
            reader.readAsDataURL(e.target.files[0]);
            setProfileImageDisplay('none');
            setShowSpinner(true);
        }
    };

    const onLoad = useCallback(img => {
        setImgRef(img);
    }, []);


    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        //canvas.width = crop.width;
        //canvas.height = crop.height;
        canvas.width = 500;
        canvas.height = 500;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            500,
            500
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    };


    const loadingSpinner = (showSpinner) => {

        if(showSpinner)
        {
            return(
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300}}>
                    <CircularProgress />
                </div>
            )
        }
        else
        {
            return '';
        }

    }


    const cropControls = (showControls) => {

        if(showControls)
        {
            return(
                <>
                    <Button onClick={saveCrop}>Speichern</Button> 
                    <Button onClick={closeCrop}>Abbrechen</Button>
                </>
            )
        }
        else{
            return '';
        }
        
    }

    const saveCrop = async () => {
        getCroppedImg(imgRef, crop, props.userProfile.firstName + props.userProfile.lastName + props.userProfile.birthday + '.jpg').then((blob)=>{
            setUpImg(); 
            setShowControls(false);
            setShowSpinner(true);
            uploadProfileImage(blob).then((path)=>{
                getProfileImage(path).then((url)=>{
                    setProfileImage(url);
                    setShowSpinner(false);
                    setProfileImageDisplay('block');
                })
            });
        });
    }

    const closeCrop = () => {
        setUpImg(); 
        setShowControls(false);
        setProfileImageDisplay('block');
    }

    return (
        <>
            <div className="profile-image-edit" style={{display: profileImageDisplay}}>
                <div className="overlay">
                    <Button component="label">
                        <Edit />&nbsp;&nbsp;Bild ändern
                        <input
                            type="file"
                            accept="image/*" 
                            onChange={onSelectFile}
                            style={{ display: "none" }}
                        />
                    </Button>
                </div>
                <img src={profileImage} alt='' />
            </div>
            {loadingSpinner(showSpinner)}
            <ReactCrop
                src={upImg}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={c => setCrop(c)}
            />
            {cropControls(showControls)}
        </>
    )
}
