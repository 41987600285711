import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardTimePicker,KeyboardDatePicker } from "@material-ui/pickers";
import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';

import {firebaseHandle} from '../user_accounts/Authentification';

import NewAppointmentInterval from './NewAppointmentInterval';

export default class NewAppointment extends Component {

    constructor(props) {

        super(props);
        this.state = {
            newDialogOpen: false,
            newFrom: null,
            newUntil: null,
            newRoom: 0,
            newTitle: null,

            intervalType: 0,
            intervalValue: 1,
            intervalEndDate: this.props.selectedDate.clone(),
    
            newFromError: false,
            newUntilError: false,
            newRoomError: false,
            newTitleError: false,
            newNameError: false,
            intervalValueError: false,
            intervalEndDateError: false,

            newAppointmentIntervalData: {},

            color: '#ff1744'
            
        }
    }

    colors = ['#ff1744','#f50057','#d500f9','#651fff','#3d5afe','#2979ff','#ffc400','#ff9100','#ff3d00', '#42b527'];

    handleNewDialogOpen = () => {
        this.setState({newDialogOpen: true, color: this.colors[Math.floor(Math.random()*this.colors.length)]});
    };

    handleNewDialogClose = () => {
        this.setState({
            newDialogOpen: false,
            newFrom: null,
            newUntil: null,
            newRoom: 0,
            newTitle: null,

            intervalType: 0,
            intervalValue: 1,
            intervalEndDate: this.props.selectedDate.clone(),
            
            newFromError: false,
            newUntilError: false,
            newRoomError: false,
            newTitleError: false,
            intervalValueError: false,
            intervalEndDateError: false
        });
        this.errorMessages = [];
    };

    handleNewRoomChange = event => {
        this.setState({newUntil: event.target.value});
    };

    handleNewTitleChange = event => {
        this.setState({newTitle: event.target.value});
    };

    handleNewNameChange = event => {
        this.setState({newName: event.target.value});
    };

    handleNewFromChange = date => {
        if(date != null){
            this.setState({
                newFrom: date.year(this.props.selectedDate.year()).month(this.props.selectedDate.month()).date(this.props.selectedDate.date())
            })
        }
    };

    handleNewUntilChange = date => {
        if(date != null){
            this.setState({
                newUntil: date.year(this.props.selectedDate.year()).month(this.props.selectedDate.month()).date(this.props.selectedDate.date())
            })
        }
    };

    handleNewAppointmentIntervalFinished = () =>
    {
        this.props.fetchAppointments(this.props.selectedDate);
    }


    errorMessages = [];

    checkNewInput = () => {
        
        let newFromError = false;
        let newUntilError = false;
        let newRoomError = false;
        let newTitleError = false;
        let overlapError = false;
        let intervalValueError = false;
        let intervalEndDateError = false;
        this.errorMessages = [];

        if(this.state.newFrom == null){newFromError = true;}
        if(this.state.newUntil == null){newUntilError = true;}
        if(this.state.newRoom === 0){newRoomError = true;}
        if(this.state.newTitle == null){newTitleError = true;}

        
        if(this.state.newFrom != null && this.state.newUntil != null && this.props.buchungen != null)
        {
            for (const key in this.props.buchungen) {
            
                if(this.props.buchungen[key].raum === this.state.newRoom)
                {
                    const von = new Date(this.props.buchungen[key].von).getTime() / 1000;
                    const bis = new Date(this.props.buchungen[key].bis).getTime() / 1000;
    

                    if( ((von < this.state.newUntil.unix() && von > this.state.newFrom.unix()) ||  (bis < this.state.newUntil.unix() && bis > this.state.newFrom.unix())) || ((this.state.newFrom.unix() < bis && this.state.newFrom.unix() > von) ||  (this.state.newUntil.unix() < bis && this.state.newUntil.unix() > von)) )
                    {
                        overlapError = true;
                        this.errorMessages.push(<p key={key+"-overlapError"}>Zeitüberschneidung mit: {this.props.buchungen[key].titel}</p>);
                    }
                }
            }
        }

        if(this.state.newFrom != null && this.state.newUntil != null )
        {
            if(this.state.newFrom.unix() >= this.state.newUntil.unix())
            {
                newFromError = true;
                newUntilError = true;

                this.errorMessages.push(<p key="error-earlyEnd">Beginn muss vor dem Ende liegen</p>);
            }
        }
        else
        {
            if(this.state.newFrom != null){newFromError = true;}
            if(this.state.newUntil != null ){newUntilError = true;}
        }

        // Interval Zeugs
        if(this.state.intervalType > 0)
        {
            if(isNaN(this.state.intervalValue) || this.state.intervalValue < 1)
            {
                intervalValueError = true;
            }

            if(this.state.intervalEndDate.unix() <= this.props.selectedDate.unix() )
            {
                intervalEndDateError = true;
            }

        }

        


        if(!newFromError && !newUntilError && !newRoomError && !newTitleError && !overlapError && !intervalEndDateError && !intervalValueError)
        {
            const newAppointment = {
                von: this.state.newFrom,
                bis: this.state.newUntil,
                raum: this.state.newRoom,
                titel: this.state.newTitle,
                name: this.props.userProfile.firstName + ' ' + this.props.userProfile.lastName,
                farbe: this.state.color,
                user: this.props.user.uid
            }

            if(this.state.intervalType === 0)
            {
                newAppointment.von = this.state.newFrom.format();
                newAppointment.bis = this.state.newUntil.format();

                firebaseHandle.rtdb.ref('buchungen/'+ this.props.selectedDate.format('YYYY-MM-DD')).push(newAppointment).then(() => {
                    this.props.fetchAppointments(this.props.selectedDate);
                })
            }
            else
            {
                const intervalDates = [];
                let momentKey;
                switch (this.state.intervalType) {
                    case 1:
                        momentKey = 'd';
                    break;
        
                    case 2:
                        momentKey = 'w';
                    break;
        
                    case 3:
                        momentKey = 'M';
                    break;

                    default:
                }


                let iterator = 1;
                let intervalDate = this.props.selectedDate.clone();
                while (intervalDate.unix() < this.state.intervalEndDate.hour(23).minute(59).second(59).unix()) {
                    
                    intervalDates.push(intervalDate.clone());

                    intervalDate = this.props.selectedDate.clone();
                    intervalDate.add(this.state.intervalValue * iterator, momentKey);

                    iterator++;
                }

                this.setState({newAppointmentIntervalData: {appointment: newAppointment, dates: intervalDates}})
            }

            this.handleNewDialogClose();
            
        }
        else
        {
            this.setState({
                newFromError: newFromError,
                newUntilError: newUntilError,
                newRoomError: newRoomError,
                newTitleError: newTitleError,
                intervalValueError: intervalValueError,
                intervalEndDateError: intervalEndDateError
            });
        }

        
    }


    render() {

        let intervalTypeText = {};
        let intervalForm;

        switch (this.state.intervalType) {
            case 1:
                this.state.intervalValue > 1 ? intervalTypeText = {start: 'Alle', end: 'Tage'} : intervalTypeText = {start: 'Jeden', end: 'Tag'};
            break;

            case 2:
                this.state.intervalValue > 1 ? intervalTypeText = {start: 'Alle', end: 'Wochen'} : intervalTypeText = {start: 'Jede', end: 'Woche'};
            break;

            case 3:
                this.state.intervalValue > 1 ? intervalTypeText = {start: 'Alle', end: 'Monate'} : intervalTypeText = {start: 'Jeden', end: 'Monat'};
            break;
        
            default:
                intervalForm = '';
            break;
        }

        if(this.state.intervalType > 0)
        {
            intervalForm = (
                <Paper style={{padding: '5px 10px'}}>
                    <span>{intervalTypeText.start}</span> 
                    <TextField value={this.state.intervalValue} onChange={event => this.setState({intervalValue: event.target.value}) } style={{diplay: 'inline-block', verticalAlign: 'middle', width: '20px', margin: '0 5px'}} error={this.state.intervalValueError}/> 
                    <span>{intervalTypeText.end}</span>
                    <br />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="DD. MM. YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        label="Wiederholen bis zum"
                        value={this.state.intervalEndDate}
                        onChange={date => this.setState({intervalEndDate: date})}
                        autoOk={true}
                        error={this.state.intervalEndDateError}
                    />
                </Paper>
            );
        }


        return (

            <>
                {this.props.user ? <Fab color="secondary" style={{position: 'fixed', right: '30px', bottom: '30px'}} onClick={this.handleNewDialogOpen}><Icon>add</Icon></Fab> : ''}

                <Dialog
                    open={this.state.newDialogOpen}
                    onClose={this.handleNewDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Neuer Termin</DialogTitle>
                    <DialogContent>

                        <div style={{color: '#f44336'}}>
                            {this.errorMessages}
                        </div>
                        
                        <FormControl variant="filled" style={{width: '100%'}}>
                            <InputLabel id="newRoom-label">Raum</InputLabel>
                            <Select
                            labelId="newRoom-label"
                            id="newRoom"
                            value={this.state.newRoom}
                            onChange={event => this.setState({newRoom: event.target.value})}
                            error={this.state.newRoomError}
                            >
                            <MenuItem value={0}>
                                <em>Bitte Auswählen</em>
                            </MenuItem>
                            <MenuItem value={1}>1. Etage</MenuItem>
                            <MenuItem value={2}>2. Etage groß</MenuItem>
                            <MenuItem value={21}>2. Etage klein</MenuItem>
                            <MenuItem value={3}>3. Etage</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <KeyboardTimePicker
                            style={{width: '100%', marginTop: '15px'}}
                            label="Beginn"
                            placeholder="00:00"
                            mask="__:__"
                            value={this.state.newFrom}
                            onChange={this.handleNewFromChange}
                            ampm={false}
                            autoOk={true}
                            error={this.state.newFromError}
                        />
                        <br />
                        <KeyboardTimePicker
                            style={{width: '100%', marginTop: '15px'}}                            
                            label="Ende"
                            placeholder="00:00"
                            mask="__:__"
                            value={this.state.newUntil}
                            onChange={this.handleNewUntilChange}
                            ampm={false}
                            autoOk={true}
                            error={this.state.newUntilError}
                        />
                        <br />
                        <TextField
                            style={{width: '100%'}}
                            id="appointment-title"
                            label="Titel"
                            margin="normal"
                            onChange={this.handleNewTitleChange}
                            error={this.state.newTitleError}
                        />

                        <FormControl  style={{width: '100%', marginTop: '20px'}}>
                            <Select value={this.state.intervalType} onChange={event => this.setState({intervalType: event.target.value, intervalValue: 1})} displayEmpty>
                            <MenuItem value={0}>Einmalig</MenuItem>
                            <MenuItem value={1}>Wiederholen nach Tagen</MenuItem>
                            <MenuItem value={2}>Wiederholen nach Wochen</MenuItem>
                            <MenuItem value={3}>Wiederholen nach Monaten</MenuItem>
                            </Select>
                        </FormControl>

                        {intervalForm}

                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleNewDialogClose} color="secondary">
                        Abbrechen
                    </Button>
                    <Button onClick={this.checkNewInput} color="primary" autoFocus>
                        Speichern
                    </Button>
                    </DialogActions>
                </Dialog>

                <NewAppointmentInterval data={this.state.newAppointmentIntervalData} finished={this.handleNewAppointmentIntervalFinished}></NewAppointmentInterval>

            </>
        )
    }
}
