import React, {useState, useEffect} from 'react'
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import "moment/locale/de";
import { firebaseHandle } from '../user_accounts/Authentification';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

moment.locale("de");

export default function Vacation(props) {


    const [vacationFrom, setVacationFrom] = useState((props.vacation && props.vacation[0] && props.vacation[0] !== null) ? moment.unix(props.vacation[0].seconds) : null);
    const [vacationUntil, setVacationUntil] = useState((props.vacation && props.vacation[1] && props.vacation[1] !== null) ? moment.unix(props.vacation[1].seconds) : null);
    const [representation, setRepresentation] = useState(null);
    const [representationValue, setRepresentationValue] = useState(null);
    const [representationList, setRepresentationList] = useState([]);

    useEffect(() => {
        firebaseHandle.db.collection("Users").orderBy("firstName").get().then((res)=>{
            setRepresentationList(res.docs);
        });
        if(props.vacation && props.vacation[2] && props.vacation[2] !== null){
            props.vacation[2].get().then(element => {
                setRepresentation(element)
            })
        }
        
    }, [])

    const save = (vacationFrom, vacationUntil, representationValue) =>
    {
        const vacationArray = [];
        vacationArray.push(vacationFrom !== null ? vacationFrom.toDate() : vacationFrom);
        vacationArray.push(vacationUntil !== null ? vacationUntil.hour(23).minute(59).second(59).toDate() : vacationUntil);

        if(representationValue !== null)
        {
            vacationArray.push(representationValue.ref);
        }


        props.userProfileRef.update({
            vacation: vacationArray
        });
    }

    representationList.forEach(element => {
       if(representation !== null && representationValue === null && element.data().email == representation.data().email) 
       {
            setRepresentationValue(element);
       }            
    });

    return (
        <div>
            
            <h2>🌴 Urlaub</h2>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                margin="normal"
                id="date-picker-vacation-from"
                label="Von einschließlich"
                format="DD/MM/YYYY"
                value={vacationFrom}
                onChange={(date) => {
                    setVacationFrom(date);
                    save(date, vacationUntil, representationValue);
                }} 
                />
                <KeyboardDatePicker
                margin="normal"
                id="date-picker-vacation-until"
                label="Bis einschließlich"
                format="DD/MM/YYYY"
                value={vacationUntil}
                onChange={(date) => {
                    setVacationUntil(date);
                    save(vacationFrom, date, representationValue);
                }} 
                />
            </MuiPickersUtilsProvider>
            <Autocomplete
                options={representationList}
                getOptionLabel={option => option.data().firstName + ' ' + option.data().lastName}
                style={{marginTop: '20px' }}
                value={representationValue}
                onChange={(event, newValue) => {
                    setRepresentationValue(newValue);
                    setRepresentation(newValue);
                    save(vacationFrom, vacationUntil, newValue);
                }}
                renderInput={params => <TextField {...params} label="Vertretung" variant="outlined" style={{ width: '100%' }} />}
            />
        </div>
    )
}
