import React from 'react';
import Scheduler from './Scheduler';

import { DatePicker } from "@material-ui/pickers";
import InputBase from '@material-ui/core/InputBase';

import moment from "moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import "./MeetingRooms.css";
import "moment/locale/de";

moment.locale("de");

export default function MeetingRooms(props) {

  const [selectedDate, handleDateChange] = React.useState(new Date());

  const renderInputBase = (props) => (
    <InputBase
      inputProps={{ style: {cursor: 'pointer', textAlign: 'right',}, readOnly: true }}
      onClick={props.onClick}
      id={props.id}
      value={props.value}
      disabled={props.disabled}
      {...props.inputProps}
    />
  );

  return (
    <div className="meeting-rooms">
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="de">
        <div className='meeting-rooms-date-picker'>
            <DatePicker 
                value={selectedDate}
                onChange={handleDateChange}
                autoOk
                format = 'dd. Do MMMM YYYY'
                cancelLabel='Abbruch'
                TextFieldComponent= {renderInputBase}
            />
        </div>
            
        <Scheduler selectedDate={moment(selectedDate)} userProfile={props.userProfile} user={props.user} />
      </MuiPickersUtilsProvider>
    </div>
  );
}
