import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import {firebaseHandle} from '../user_accounts/Authentification';
import {Edit, Delete, Add} from '@material-ui/icons';
import CreateEditManual from './CreateEditManual';
import './Manual.css';

export default function Manual(props) {

    const [manuals, setManuals] = useState([]);
    const [showCreateEdit, setShowCreateEdit] = useState(false);
    const [editManual, setEditManual] = useState({title: '', file: ''});
    const [editor, setEditor] = useState(false);

    useEffect(() => {

        setEditor(props.userProfile.roles.includes('anleitungen-admin'));

        firebaseHandle.db.collection("Manual").onSnapshot((manuals) => {
            
            const tmpUrls = [];
            const tmpManuals = [];

            manuals.forEach((manual) => {
                const data = manual.data();
                data.id = manual.id;
                data.edit = <IconButton onClick={(e)=>{handleEdit(e, data)}}><Edit /></IconButton>;
                data.delete = <IconButton onClick={(e)=>{handleDelete(e, manual.id)}}><Delete /></IconButton>;
                tmpManuals.push(data);
                tmpUrls.push(firebaseHandle.storageRef.child(data.file).getDownloadURL());
                  
            });

            Promise.all(tmpUrls).then((urls)=>{
                for(let i = 0; i < urls.length; i++)
                {
                    tmpManuals[i].url = urls[i];
                }
                setManuals(tmpManuals);
            }).catch(function(error) {
                console.log(error);
            });
            
        });

    }, []);


    const handleEdit = (e, manual) => {
        e.stopPropagation();
        setEditManual(manual);
        setShowCreateEdit(true);
    }

    const handleDelete = (e, id) => {
        e.stopPropagation();
        firebaseHandle.db.doc('/Manual/'+id).delete();
    }

    const handleCloseCreateEdit = () => {
        setEditManual({title: '', file: ''});
        setShowCreateEdit(false);
    }

    return (
        <>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>Titel</TableCell>
                    {editor ? <TableCell></TableCell> : null}
                    {editor ? <TableCell></TableCell> : null}
                </TableRow>
                </TableHead>
                <TableBody>
                {manuals.map((row, index) => (
                    <TableRow key={index} onClick={()=>{window.open(row.url, '_blank')}} >
                        <TableCell>{row.title}</TableCell>
                        {editor ? <TableCell>{row.edit}</TableCell> : null}
                        {editor ? <TableCell>{row.delete}</TableCell> : null}
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>

            {editor ? <Fab color="secondary" style={{position: 'fixed', bottom: 20, right: 20}} onClick={() => {setShowCreateEdit(true)}}><Add /></Fab> : null }

            <CreateEditManual show={showCreateEdit} close={handleCloseCreateEdit} manual={editManual} />
        </>
    )
}
