import React, { useState } from 'react';
import './Login.css';
import { loginUser } from './Authentification';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState('');


    const submitLogin = (event) => {
        event.preventDefault();
        setAlert('');
        loginUser(email, password).catch(function(error) {
            setAlert(<Alert style={{marginBottom: '25px'}} severity="error">E-Mail oder Passwort falsch</Alert>)
        });
    }


    return (
        <Grid container spacing={0} justify="center">
            <Grid item xs={12} sm={10} md={6} lg={3}>
                <Paper style={{padding: '20px'}} elevation={15} >
                    <form id="loginForm" noValidate autoComplete="on" onSubmit={submitLogin}>
                        {alert}
                        <TextField label="E-Mail" variant="outlined" value={email} onChange={(event) => {setEmail(event.target.value)}} />
                        <TextField label="Passwort" variant="outlined" type="password" value={password} onChange={(event) => {setPassword(event.target.value)}} />
                        <Button variant="contained" color="primary" type="submit" style={{float: 'right'}}>
                            Anmelden
                        </Button>
                    </form>
                </Paper>
                <br />
                <Link component={RouterLink} to="/signup" style={{marginRight: '10px'}}>Registrieren</Link> 
                <Link component={RouterLink} to="/passwort-vergessen">Passwort vergessen?</Link>
            </Grid>
        </Grid>
        
    )
}
